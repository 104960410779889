<template>
  <v-row class="select-form pl-4 pr-3 mb-2">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
      <h6 class="pt-2 pl-4" style="font-weight: bold;">{{title}}</h6>
    </v-col>
    <v-col class="pt-0 pr-4">
      <ValidationErrors :messages="errors">
        <v-textarea
          v-model="commentRef"
          class="mt-0"
          rows=5
          :error="errors?.length ? true : false"
        />
      </ValidationErrors>
    </v-col>
  </v-row>
</template>

<script>
  import { ref, watch } from 'vue'
  import ValidationErrors from "@/components/Common/ValidationErrors.vue"
  import i18n from '@/lang/i18n';

  export default {
    props: {
      comment: String,
      errors: Array,
      windowInnerWidth: Number
    },
    setup(props, context) {
      const title = i18n.global.t('pages.operationOffices.comment')
      const commentRef = ref('');

      const unwatch = watch(() => props.comment, (value) => {
        commentRef.value = value;

        unwatch();
      });

      watch(() => commentRef.value, (value) => {
          context.emit('inputComment', { comment: value });
      });

      return {
        title,
        commentRef,
        i18n
      }
    },
    components: {
      ValidationErrors
    }
  }
</script>

<style>
.select-form .v-label {
  display: none;
}
.select-form .v-field__field {
  padding-top: 7px;
}
</style>
