<script setup>
  import StatusChangeConfirmModal from "@/components/OpearationOfficeListParts/StatusCangeConfirmModal"
  import AllowExternalSharedStatusChangeConfirmModal from "@/components/OpearationOfficeListParts/AllowExternalSharedStatusChangeConfirmModal"
  import { formatDate } from "@/services/functions"
  import { reactive } from 'vue'
  import EditLinkIcon from "@/components/Common/EditLinkIcon"
  import i18n from '@/lang/i18n';

  const ID = 'id';
  const OFFICEABLE_NAME = 'officeable_name';
  const OPERATION_OFFICE_NAME = 'operation_office_name';
  const CONTACT_NAME = 'contact_name';
  const STATUS = 'status';
  const ALLOW_EXTERNAL_SHARED = 'allow_external_shared';
  const UPDATED_AT = 'updated_at';
  const ACCOUNT_NAME = 'account_name';

  const props = defineProps({
    selfRoleId: {
      type: Number,
      defaulf: 2
    },
    operationOffices: [],
    changeStatusAuthority: {
      type: Boolean,
      default: false
    },
    changeSharedAuthority: {
      type: Boolean,
      default: false
    },
    listLoading: {
      type: Boolean,
      default: true
    },
    updatingStatus: {
      type: Boolean,
      default: false
    },
    modelValue: null,
  })

  const emit = defineEmits(['changeSortOrder', 'changeStatus'])
  const sortableItems = reactive({
    [i18n.global.t('pages.operationOffices.list.id')]: ID,
    [i18n.global.t('pages.operationOffices.list.officeableName')]: OFFICEABLE_NAME,
    [i18n.global.t('pages.operationOffices.operationOfficeName')]: OPERATION_OFFICE_NAME,
    [i18n.global.t('pages.operationOffices.contactName')]: CONTACT_NAME,
    [i18n.global.t('pages.operationOffices.list.status')]: STATUS,
    [i18n.global.t('pages.operationOffices.list.allowExternalShared')]: ALLOW_EXTERNAL_SHARED,
    [i18n.global.t('pages.operationOffices.list.updatedAt')]: UPDATED_AT,
    [i18n.global.t('pages.operationOffices.list.updatedBy')]: ACCOUNT_NAME
  })
  const currentSort = reactive({ item: 'id', type: 'ASC' })
  const sortTypes = reactive(['ASC', 'DESC'])

  function headers () {
    if (!props.changeSharedAuthority) {
      return [
        i18n.global.t('pages.operationOffices.list.id'),
        i18n.global.t('pages.operationOffices.list.officeableName'),
        i18n.global.t('pages.operationOffices.operationOfficeName'),
        i18n.global.t('pages.operationOffices.contactName'),
        i18n.global.t('pages.operationOffices.list.passwordExpirationDate'),
        i18n.global.t('pages.operationOffices.list.ipRestriction'),
        i18n.global.t('pages.operationOffices.list.limitNumberOfInputErrors'),
        i18n.global.t('pages.operationOffices.telephoneNumber'),
        i18n.global.t('pages.operationOffices.list.workStatus.title'),
        i18n.global.t('pages.operationOffices.comment'),
        i18n.global.t('pages.operationOffices.list.status'),
        i18n.global.t('pages.operationOffices.list.updatedAt'),
        i18n.global.t('pages.operationOffices.list.updatedBy'),
        i18n.global.t('pages.operationOffices.list.edit')
      ]
    }
    return [
      i18n.global.t('pages.operationOffices.list.id'),
      i18n.global.t('pages.operationOffices.list.officeableName'),
      i18n.global.t('pages.operationOffices.operationOfficeName'),
      i18n.global.t('pages.operationOffices.contactName'),
      i18n.global.t('pages.operationOffices.list.passwordExpirationDate'),
      i18n.global.t('pages.operationOffices.list.ipRestriction'),
      i18n.global.t('pages.operationOffices.list.limitNumberOfInputErrors'),
      i18n.global.t('pages.operationOffices.telephoneNumber'),
      i18n.global.t('pages.operationOffices.list.workStatus.title'),
      i18n.global.t('pages.operationOffices.comment'),
      i18n.global.t('pages.operationOffices.list.status'),
      i18n.global.t('pages.operationOffices.list.allowExternalShared'),
      i18n.global.t('pages.operationOffices.list.updatedAt'),
      i18n.global.t('pages.operationOffices.list.updatedBy'),
      i18n.global.t('pages.operationOffices.list.edit')
    ]
  }
  function officeableName (office) {
    if (office.management_officeable['company_name'] == null) return ''
    return office.management_officeable['company_name']
  }
  function datetimeFormat (datetime) {
    const d = new Date(datetime)
    return formatDate(d)
  }
  function viewCount (count) {
    if (count == 0) return 'N/A'
    return count
  }
  function ip_keeps_effective (ip) {
    if (ip != 0) return i18n.global.t('pages.operationOffices.list.yes')
    return i18n.global.t('pages.operationOffices.list.no')
  }
  function commnet (commnet) {
    if (commnet != null) return i18n.global.t('pages.operationOffices.list.yes')
    return i18n.global.t('pages.operationOffices.list.no')
  }
  function workstatus (workstatus) {
    switch (workstatus){
      case 1:
        return i18n.global.t('pages.operationOffices.list.workStatus.haveNegotiation')
      case 2:
        return i18n.global.t('pages.operationOffices.list.workStatus.operating')
      case 3:
        return i18n.global.t('pages.operationOffices.list.workStatus.dissolutionContract')
      case 4:
        return i18n.global.t('pages.operationOffices.list.workStatus.onHold')
      default: ''
    }
  }
  function emitChangeSortOrder (sortItem, sortType = null) {
    if (!sortableItem(sortItem)) return
    const item = sortableItems[sortItem]
    if (sortType) {
      currentSort.type = sortType
    } else {
      if (currentSort.item == item) {
        const type = sortTypes.find(type => type != currentSort.type)
        currentSort.type = type
      } else {
        currentSort.type = sortTypes[0]
      }
    }
    currentSort.item = item
    emit('changeSortOrder', currentSort.item, currentSort.type)
  }
  function sortableItem (itemName) {
    return Object.keys(sortableItems).includes(itemName)
  }
  function addClassIfSortable (itemName) {
    if (!sortableItem(itemName)) return
    return 'sort-icon pl-2 pt-1';
  }
  function highLightIfCurrentSort (item, type) {
    if (currentSort.item != sortableItems[item] || currentSort.type != type) return
    return 'font-weight: bold;'
  }
  function applyLeftStyle (index) {
    if (index == 0) return 'left: 0;'
  }
  function emitChangeStatus (params, key) { emit('changeStatus', params, key) }
</script>

<template>
  <div class="operation-office-list">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>{{ i18n.global.t('pages.common.list.reading') }}</label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th v-for="(item, index) in headers()" :key="index" v-show="item != i18n.global.t('pages.operationOffices.list.edit') || selfRoleId == 1" class="table-header column-space" :style="applyLeftStyle(index)">
              <div class="d-flex">
                <span v-show="sortableItem(item)" style="display: inline-block;">
                  <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                  <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
                </span>
                <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="operationOffices?.length != 0">
            <tr v-for="operationOffice in operationOffices" :key="operationOffice.id">
              <td class="column-space fix-td">{{operationOffice.id}}</td>
              <td class="column-space">{{officeableName(operationOffice)}}</td>
              <td class="column-space">{{operationOffice.operation_office_name}}</td>
              <td class="column-space">{{operationOffice.contact_name ? operationOffice.contact_name : ''}}</td>
              <td class="column-space">{{viewCount(operationOffice.password_expire_days)}}</td>
              <td class="column-space">{{ip_keeps_effective(operationOffice.ip_keeps_effective)}}</td>
              <td class="column-space">{{viewCount(operationOffice.login_error_limit_times)}}</td>
              <td class="column-space">{{operationOffice.telephone_number}}</td>
              <td class="column-space">{{workstatus(operationOffice.work_status)}}</td>
              <td class="column-space">{{commnet(operationOffice.comment)}}</td>
              <td class="column-space">
                <StatusChangeConfirmModal
                  :item="operationOffice"
                  :updatableStatusButton="changeStatusAuthority"
                  :updatingStatus="updatingStatus"
                  @submit="emitChangeStatus"
                />
              </td>
              <td
                v-if="props.changeSharedAuthority"
                class="column-space"
              >
                <AllowExternalSharedStatusChangeConfirmModal
                  :item="operationOffice"
                  :updatingStatus="updatingStatus"
                  @submit="emitChangeStatus"
                />
              </td>
              <td class="column-space">{{datetimeFormat(operationOffice.updated_at)}}</td>
              <td class="column-space">{{operationOffice.updated_by.last_name + ' ' + operationOffice.updated_by.first_name}}</td>
              <td class="column-space">
                <EditLinkIcon :name="'OperationOfficeForm'" :id="operationOffice.id" />
              </td>
            </tr>
          </template>
          <template v-else-if="operationOffices?.length == 0 && !listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>
<style>
.operation-office-list .list table {
  position: absolute;
}
.operation-office-list .list td {
  word-break: keep-all;
}
.operation-office-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.operation-office-list {
  position: relative;
}
.operation-office-list .list {
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.operation-office-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.operation-office-list .list .id-column {
  min-width: 2em;
}
.operation-office-list .list .updated-at-column {
  min-width: 10em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.list-loading {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.operation-office-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
