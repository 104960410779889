export default {
  title: "地点一覧",
  name: '名称',
  telephoneNumber: '電話番号',
  address: "住所",
  list: {
    id: "ID",
    range: "範囲",
    updateAt: "最終更新日",
    edit: "編集"
  },
  form: {
    managementOffice: '管理会社',
    operationOffice: '運営会社',
    regist: "登録",
    update: "更新",
    location: "所在地",
    radius: "範囲 (m)",
    notice1: '※登録した地点は次運行から反映されます。',
    notice2: '  過去の運行には反映されません。',
  },
  csv: {
    upload: "CSVアップロード",
    importTitle: "地点の入力",
    settingId: "CSVに設定するoperation_office_idは",
    here: "こちら",
    seeIdList: "のID欄をご覧ください。",
    currentLoginId: "現在ログイン中のoperation_office_idは { operationOfficeId } です。",
  },
  messages: {
    invalidTelephoneNumber: "電話番号は10桁から11桁の半角数字を入力してください。",
    select: "選択してください",
    input: "入力してください",
    executionSearch: "検索を実行してください",
    inputNumber: "数字を入力してください",
    inputNumberLessThan100000: "100000以下の数値を入力してください",
    addressNotFound: "住所が見つかりません",
    success: "{ label }に成功しました。",
    failed: "{ label }に失敗しました。"
  },
  errors: {
    getList: "一覧の取得に失敗しました。"
  }
}
