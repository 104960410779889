export default {
  title: {
    list: "運営会社一覧",
    listCreate: "運営会社一覧/作成",
    listEdit: "運営会社一覧/編集",
    edit: "運営会社/編集"
  },
  operationOfficeName: "運営会社名",
  contactName: "担当者名",
  telephoneNumber: "電話番号",
  comment: "コメント",
  hours: "時間",
  minutes: "分",
  month: "月",
  days: "日",
  list: {
    id: "ID",
    officeableName: "管理会社名",
    passwordExpirationDate: "PW有効期限",
    ipRestriction: "IP制限",
    limitNumberOfInputErrors: "入力ミス制限回数",
    status: "ステータス",
    allowExternalShared: "外部共有許可",
    updatedAt: "最終更新日",
    updatedBy: "最終更新者",
    edit: "編集",
    yes: "有",
    no: "無し",
    workStatus: {
      title: "担当者ステータス",
      haveNegotiation: "商談を持ちます",
      operating: "オペレーティング",
      dissolutionContract: "契約の解散",
      onHold: "保留中"
    },
    enable: "有効",
    disable: "無効",
    confirmationModal: {
      statusConfirm: "運営会社を{ status }にします。よろしいですか？",
      office: "このオフィス",
      confirmDeclineShare: "の共有を拒否してよろしいでしょうか。",
      confirmShare: "を共有してよろしいでしょうか。"
    },
    messages: {
      allowExternalShared: "外部共有許可の保存に成功しました。",
    }
  },
  csv: {
    import: "運営会社の入力"
  },
  form: {
    managementOffice: "管理会社",
    reportDeadLineDay: {
      title: "月報の締日",
      endOfTheMonth: "月末",
      oneDayBefore: "月末の1日前",
      twoDayBefore: "月末の2日前",
    },
    reckonedDate: "起算日",
    message: {
      isNotReckonedDate: "起算日を設定してください。",
      hasIttenkoFailedAt: "IT点呼の認証情報が間違えています。もう一度ご確認の上変更してください。"
    },
    ittenko: {
      setting: "IT点呼を設定する",
      id: "IT点呼会社ID",
      privateKey: "IT点呼秘密鍵",
      acquisitionTime: "取得時間",
      beforeLeaving: "出庫前",
      afterReturning: "帰庫後",
    },
    tdsensor: {
      batteryAlert: '電池残量通知'
    },
    sensorAlertSetting: {
      notificationMail: "メール (温度管理通知先)",
    },
    externalUrls: {
      title: "外部ASP_URL",
      name: "名称",
      nameSample: "(例)動態管理",
      url: "URL",
      urlSample: "(例)https://",
    },
    trackingService: "トラッキングサービス",
    operationOfficeNameKana: "運営会社名カナ",
    salesforceId: "Salesforce ID",
    address: {
      postCode: "郵便番号",
      prefectures: "都道府県",
      address1: "住所 1",
      address2: "住所 2",
    },
    fax: "ファックス",
    email: "メール",
    allocatedWork: {
      title: "自動作業割り当て",
      time: "割り当てる時間",
      stoppingMoreThanMinutes: "分以上の停車",
      work: "割り当てる作業",
      loading: "荷積",
      unloading: "荷卸",
      shipping: "荷待",
      incidentalWork: "付帯作業",
      refueling: "給油",
      wait: "待機",
      break: "休憩",
    },
    alertSetting: {
      title: "拘束時間上限設定",
      yearSetting: "年間設定",
      monthSetting: "月ごとに設定",
      moreThanHours: "時間以上",
      selectColor: "色選択",
      boundTimeByDays: "単日拘束日数想定",
      boundTimeByHours: "単日拘束時間目標",
    },
    maximumWorkingHours: {
      title: "労働時間上限設定",
      daysSetting: "日別で設定",
      monthSetting: "月別で設定"
    },
    overtimeWorkHourLimit: {
      title: "時間外労働時間上限設定"
    },
    cooperationList: {
      title: "連携データ",
      ittenko: "IT点呼連携"
    },
    csvDateFormat: {
      title: "時刻フォマット設定",
    },
    viewThreshold: {
      title: "速度・回転数の閾値",
      display: "表示",
      hidden: "非表示"
    },
    imprinterName: "押印者名",
    validTdsensor: "温度管理設定を有効にする",
    accounting: {
      title: "経理情報",
      cutOffDates: "締め日",
      paymentSites: "支払いサイト",
      paymentDays: "支払日",
      currentMonth: "同月",
      nextMonth: "翌月",
      monthAfterNext: "翌翌月"
    },
    personInCharge: {
      title: "管理会社担当者",
      list: "一覧",
      selected: "選択済",
    }
  }
}
