<template>
  <div class="d-flex" style="min-height: 50vw;">
    <div class="content p-4 w-100 flex-column">
      <div class="h-100">
        <div class="d-flex justify-content-between">
          <h4 class="card-title mt-0">{{title}}</h4>
          <div class="pr-3 align-self-center">
            <ManualLink :pageName="pageName" />
          </div>
        </div>
        <OperationOfficeFilter
          v-model="operationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <RankingSettings v-if="rankingSettings"
          v-show="selectedOperationOffice"
          v-model="rankingSettings"
          :windowInnerWidth="windowInnerWidth"
        />
        <ScoreSettings v-if="scoreSettings"
          v-show="selectedOperationOffice"
          v-model="scoreSettings"
          :windowInnerWidth="windowInnerWidth"
        />
        <div
          v-if="loaded && updateAuthority"
          class="d-flex justify-content-end"
        >
          <v-btn
            class="exec-btn"
            @click="submit"
            :loading="running">
            {{ i18n.global.t('pages.common.button.conservation') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ScoreSettings from "@/components/SafeDrivingSetting/ScoreSetting.vue"
  import OperationOfficeFilter from "@/components/SafeDrivingSetting/OperationOfficeFilter.vue"
  import RankingSettings from "@/components/SafeDrivingSetting/RankingSettings.vue"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";
  import Api from "@/services/api/ApiServiceFabrick";
  import ManualLink from "@/components/ManualLink";
  import { ref } from 'vue'
  import i18n from '@/lang/i18n';

  export default {
    props: {
      showFlash: { type: Function },
      switchContentLoading: { type: Function }
    },
    data () {
      return {
        operationOffice: {},
        selectedOperationOffice: {},
        settingMonthlyReport: {},
        rankingSettings: {},
        scoreSettings: {},
        response: {errors: {}},
        running: false,
        loaded: false,
        pageName: "safeDrivingSetting"
      }
    },
    setup() {
      document.title = i18n.global.t('pages.safeDrivingSetting.documentTitle')
      const title = i18n.global.t('pages.safeDrivingSetting.title')
      const windowInnerWidth = ref(window.innerWidth);
      const handleResize = () => {
        windowInnerWidth.value = window.innerWidth;
      }
      window.addEventListener('resize', handleResize)

      const updateAuthority = ref(true)
      const setShowableButtonArea = async () => {
        const authorityList = await AuthorityCacheApi.getWithParse()
        updateAuthority.value = !!authorityList["octlink.driving_score.update"]
      }
      setShowableButtonArea()

      return {
        title,
        windowInnerWidth,
        updateAuthority,
        i18n
      }
    },
    methods: {
      handleChangeSearchForm(operationOffice) {
        if (!operationOffice?.id) return
        this.switchContentLoading(true)
        Api().getOperationOffice(operationOffice.id, false).then((operationOffice) => {
          this.selectedOperationOffice = operationOffice
          this.settingMonthlyReport = operationOffice ? JSON.parse(operationOffice?.settings_monthly_report) : null;
          let rankingSettings = this.settingMonthlyReport?.report_setting?.ranking_settings
          let scoreSettings = this.settingMonthlyReport?.report_setting?.score_settings

          if (!this.settingMonthlyReport?.report_setting && this.settingMonthlyReport) {
            rankingSettings = this.createInitialRankingValues(this.settingMonthlyReport)
            scoreSettings = this.createInitialScoreValues(this.settingMonthlyReport)
          }
          this.rankingSettings = rankingSettings
          this.scoreSettings = scoreSettings
          this.switchContentLoading(false)
          this.loaded = true
        })
      },
      createInitialRankingValues(settingMonthlyReport) {
        settingMonthlyReport['report_setting'] = [
            {
              rank : 'A',
              max : '100',
              min : '80'
            },
            {
              rank : 'B',
              max : '80',
              min : '60'
            },
            {
              rank : 'C',
              max : '60',
              min : '40'
            },
            {
              rank : 'D',
              max : '40',
              min : '20'
            },
            {
              rank : 'E',
              max : '20',
              min : '0'
            }
          ]
        return settingMonthlyReport['report_setting']
      },
      createInitialScoreValues(settingMonthlyReport) {
        settingMonthlyReport['report_setting'] = {
          over_speed : {
            times : '1',
            score : '1'
          },
          rapid_acceleration : {
            times : '1',
            score : '1'
          },
          rapid_deceleration : {
            times : '1',
            score : '1'
          },
          sharp_steering_wheel : {
            times : '1',
            score : '1'
          }
        }
        return settingMonthlyReport['report_setting']
      },
      submit() {
        this.running = true
        if (!this.validate()) return window.scrollTo({top: 0})
        const requestParams = this.makeRequestParams()
        Api().updateReportSettings(this.operationOffice.id, requestParams).then(() => {
          this.running = false
          this.showFlash(i18n.global.t('pages.common.messages.successUpdate'), "success", true)
          this.response.errors = {};
        }).catch((error) => {
          this.errorHandle(error.response);
        })
      },
      validate() {
        let invalid = true
        this.rankingSettings.forEach((value) => {
          if (value.min == '') {
            invalid = false
          }
        })
        return invalid
      },
      makeRequestParams() {
        let rankingSettings = this.rankingSettings
        let scoreSettings = this.scoreSettings

        rankingSettings.forEach((value) => {
          delete value.lowerLimit
        })

        if (scoreSettings.checked == 'times') {
          if (scoreSettings.over_speed.seconds) {
            delete scoreSettings.over_speed['seconds']
          }
          scoreSettings.over_speed.times = scoreSettings.overSpeedTimes
          scoreSettings.over_speed.score = scoreSettings.overSpeedTimesScore
        }
        if (scoreSettings.checked == 'seconds') {
          if (scoreSettings.over_speed.times) {
            delete scoreSettings.over_speed['times']
          }
          scoreSettings.over_speed.seconds = scoreSettings.overSpeedSeconds
          scoreSettings.over_speed.score = scoreSettings.overSpeedSecondsScore
        }

        scoreSettings.rapid_deceleration.times = scoreSettings.decelerationTimes
        scoreSettings.rapid_deceleration.score = scoreSettings.decelerationScore
        scoreSettings.rapid_acceleration.times = scoreSettings.accelerationTimes
        scoreSettings.rapid_acceleration.score = scoreSettings.accelerationScore
        scoreSettings.sharp_steering_wheel.times = scoreSettings.sharpSteeringWheelTimes
        scoreSettings.sharp_steering_wheel.score = scoreSettings.sharpSteeringWheelScore

        return {
          id: this.operationOffice.id,
          ranking_settings: rankingSettings,
          score_settings: {
            over_speed: scoreSettings['over_speed'],
            rapid_deceleration: scoreSettings['rapid_deceleration'],
            rapid_acceleration: scoreSettings['rapid_acceleration'],
            sharp_steering_wheel: scoreSettings['sharp_steering_wheel'],
          }
        }
      },
      errorHandle(res) {
        switch (res.status) {
          case 422:
            this.showFlash(i18n.global.t('pages.common.messages.failedUpdate'), "error", false);
            this.running = false
            break
          case 403:
          case 404:
            location.reload();
        }
        return;
      }
    },
    watch: {
      operationOffice: {
        handler(operationOffice) {
          this.loaded = false
          if (!operationOffice || operationOffice == 'null') {
            this.selectedOperationOffice = null
          }
          return this.handleChangeSearchForm(this.operationOffice)
        }
      },
      rankingSettings: {
        handler() {
          return this.rankingSettings
        },
        deep : true,
      },
      scoreSettings: {
        handler() {
          return this.scoreSettings
        },
        deep : true,
      },
    },
    beforeUnmount() {
      this.switchContentLoading(false)
    },
    components: {
      ScoreSettings,
      RankingSettings,
      OperationOfficeFilter,
      ManualLink
    },
  }
</script>
<style>

</style>
