<template>
<GDialog
  v-model="dialogState"
  max-width="30%"
  transition="custom-from-bottom-transition"
>
  <div class="modal-header justify-content-center pt-3">
    <label>{{ i18n.global.t('common.confirmationModal.title') }}</label>
  </div>
  <div class="row mr-3 ml-3 pl-5 v-theme--light">
    {{ i18n.global.t('common.confirmationModal.confirmDelete') }}
  </div>
  <Buttons
    @cancel="closeModal"
    @submit="submit"
    :label="i18n.global.t('common.deleteBtn')"
    :isUpdateAuthority="isDeleteAuthority"
    :hasError="hasError"
  />
</GDialog>

<button @click="dialogState = true" class="material-symbols-outlined">
  delete
</button>

</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import Api from "@/services/api/ApiServiceFabrick";
import Buttons from '@/components/SpotParts/ModalButtons'
import { useAuth } from "@/services/user/Auth0UserProvider"
import i18n from '@/lang/i18n';

import {
  round
} from "@/services/functions";

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    showFlash: {
      type: Function,
    },
    item: {
      default: null
    },
    isDeleteAuthority: {
      type: Boolean
    }
  },
  data: () => ({
    form: {},
    dialogState: false,
  }),
  mounted() {
      this.form = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    closeModal() {
      this.dialogState = false
    },
    submit() {
      Api().deleteSpot(this.form).then(() => {
        this.dialogState = false
        this.showFlash(i18n.global.t('pages.common.messages.successDelete'), "success", true)
        this.$emit('change', this.form)
      }).catch((error) => {
        const res = error.response
        switch (res?.status) {
          case 401:
            this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
            break
          case 403:
            location.reload()
            break
          case 422:
            this.showFlash(i18n.global.t('pages.common.messages.failedDelete'), "error", false)
            this.dialogState = false
        }
      })
    },
  },
  components: {
    GDialog,
    Buttons,
  },
}
</script>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *, .v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
