<template>
  <div class="mt-4 mb-4">
    <v-table class="col-9 border">
      <tr>
        <th rowspan="6" :cols="windowInnerWidth > 800 ? 3 : 1" class="table-title mr-0 border pl-2 mr-0">
          {{ i18n.global.t('pages.safeDrivingSetting.form.scoreJudgmentSettings') }}
        </th>
        <td  :cols="windowInnerWidth > 800 ? 2 : 2" class="border p-3 text-center">
          {{ i18n.global.t('pages.safeDrivingSetting.form.rank') }}
        </td>
        <td  :cols="windowInnerWidth > 800 ? 2 : 3" class="border p-3 text-center">
          {{ i18n.global.t('pages.safeDrivingSetting.form.upperLimit') }}
        </td>
        <td  :cols="windowInnerWidth > 800 ? 2 : 3" class="border p-3 text-center">
          {{ i18n.global.t('pages.safeDrivingSetting.form.lowerLimit') }}
        </td>
      </tr>
      <tr v-for="(item, i) in settings"
        :key="i">
        <td class="border">
          <span class="pl-2" :v-model="item.rank">
            {{ `${item.rank}${i18n.global.t('pages.safeDrivingSetting.form.judge')}` }}
          </span>
        </td>
        <td class="border">
          <span class="pl-2">
            {{ `${settings[i]?.max ?? '100'}${i18n.global.t('pages.safeDrivingSetting.form.point')}` }}
          </span>
        </td>
        <td class="border py-6 text-center" v-if="item.rank == 'E'" >
          <span>
            {{ `${settings[i].min} ${i18n.global.t('pages.safeDrivingSetting.form.point')}` }}
          </span>
        </td>
        <td class="border" v-else>
          <v-col>
            <v-text-field
              type="number"
              v-model="settings[i].min"
              @change="changeSettingsValue(i)"
              class="min"
              :suffix="i18n.global.t('pages.safeDrivingSetting.form.point')"
              :error-messages="settings[i].error"
              :max="settings[i].max"
              :min="item.lowerLimit"
              :clearable="false"
            />
          </v-col>
        </td>
      </tr>
    </v-table>
  </div>
</template>

<script>
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      modelValue: {},
      error: {},
      windowInnerWidth: Number
    },
    data() {
      return{
        settings: []
      }
    },
    methods: {
      initialValue(modelValue) {
        this.settings = modelValue
        return this.settings
      },
      changeSettingsValues() {
        const settings = this.settings
        if (!settings || Object.keys(settings).length == 0) return

        settings.forEach((v, i) => {
          const lowerLimit = settings.length - (i + 1)
          settings[i]['rank'] = v.rank
          settings[i]['max'] = String(settings[i - 1]?.min ?? v.max)
          settings[i]['min'] = String(v.min)
          settings[i]['lowerLimit'] = lowerLimit
        })
      },
      changeSettingsValue(i) {
        const settings = this.settings
        if (!settings || Object.keys(settings).length == 0) return
        if (!settings[i]) settings[i] = {}

        settings.forEach((v, i) => {
          const lowerLimit = settings.length - (i + 1)
          settings[i]['rank'] = v.rank
          settings[i]['max'] = String(settings[i - 1]?.min ?? '100')
          settings[i]['min'] = String(v.min)
          settings[i]['lowerLimit'] = lowerLimit
          if (Number(settings[i]?.max) < Number(settings[i]?.min)) {
            return settings[i].min = settings[i].max
          }
          if (settings[i]['min'] < lowerLimit) {
            settings[i]['min'] = lowerLimit
          }
        })
        this.settings = settings
        this.$emit('update:modelValue', settings)
      },
    },
    watch: {
      modelValue: {
        handler() {
          this.initialValue(this.modelValue)
          this.changeSettingsValues()
        }
      },
    }
  }
</script>

<style>
  .table-title {
    vertical-align: middle;
  }
</style>