<script setup>
  import { ja } from 'date-fns/locale'
  import { useRoute } from 'vue-router'
  import { format } from 'date-fns'
  import VehicleFilter from "@/components/TemperatureSensorChartParts/VehicleFilter"
  import TemperatureReportHeader from "@/components/TemperatureSensorChartParts/TemperatureReportHeader"
  import SelectOutputTime from "@/components/TemperatureSensorChartParts/SelectOutputTime"
  import Api from "@/services/api/ApiServiceFabrick"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { reactive, ref, onMounted } from 'vue'
  import i18n from '@/lang/i18n'
  import { OUTPUT_PDF_TYPE_TEMPERATURE_GRAPH, OUTPUT_PDF_TYPE_TEMPERATURE_LIST } from "@/services/consts";
  import CreatePdfButton from "@/components/TemperatureSensorChartParts/CreatePdfButton";

  const props = defineProps({
    showFlash: Function
  })

  const route = useRoute()
  const sensorExport = ref(true)
  const { loginWithRedirect } = useAuth();
  const selectedVehicle = reactive({
    managementOffice: {},
    operationOffice: {},
    vehicle: {}
  })
  let viewChartConditions = reactive({
    outputType: '12h',
    startTime: null,
    endTime: null,
  })

  const isSelectedVehicle = ref(false)
  const selectItem = ref("12h")
  const loading = ref(false)
  const pdfGraphLoading = ref(false)
  const pdfListLoading = ref(false)
  const queryParamsVehicleId = route.query.vehicle_id || ""
  const isQueryParams = ref(route.query.vehicle_id && route.query.start_date_time && route.query.end_date_time ? true : false)
  const showableButton = ref(false)
  const pdfBtnShoweableState = ref(false)
  const xAxisLabels = ref([])
  const yAxisLabels = ref([])
  const legendsStatus = ref([true, true, true, true])
  const batteryLevel = ref([])

  const windowInnerWidth = ref(window.innerWidth)
  const handleResize = () => {
    windowInnerWidth.value = window.innerWidth
  }
  window.addEventListener('resize', handleResize);

  (async () => {
    const authorityList = await AuthorityCacheApi.getWithParse()
    sensorExport.value = authorityList["octlink.temperature.output"]
    document.title = i18n.global.t('pages.temperatureSensors.charts.title')
  })();

  function changeSelectedVehicle (item){
    if (isQueryParams.value && item.vehicle.id == route.query.vehicle_id) {
      selectedVehicle.vehicle = item.vehicle
      isSelectedVehicle.value = true
    }
  }

  function searchVehicle(value) {
    selectedVehicle.vehicle = {}
    isQueryParams.value = false
    selectedVehicle.managementOffice = value.operationOffice
    selectedVehicle.operationOffice = value.managementOffice
    selectedVehicle.vehicle = value.vehicle
    isSelectedVehicle.value = false
    if (value) isSelectedVehicle.value = true
  }

  onMounted(() => {
    if (isQueryParams.value) {
      viewChartConditions.outputType = 'absolute'
      viewChartConditions.startTime = new Date(new Date(route.query.start_date_time * 1000))
      viewChartConditions.endTime = new Date(new Date(route.query.end_date_time * 1000))
    }
  })

  function updateViewConditions (viewConditions) {
    viewChartConditions = reactive(viewConditions)
    selectItem.value = viewConditions.outputType
    if (viewConditions.outputType == 'absolute' || viewConditions.outputType == 'relative') {
      selectItem.value = 'custom'
    }
  }

  function changeChartLabels (values) {
    xAxisLabels.value = values[0][0]
    legendsStatus.value = values[0][1]
    batteryLevel.value = values[1]
    yAxisLabels.value = values[2]
  }

  function downloadPDF(type) {
    let request = {}
    if (type === OUTPUT_PDF_TYPE_TEMPERATURE_GRAPH) request = makeGraphRequestParams()
    if (type === OUTPUT_PDF_TYPE_TEMPERATURE_LIST) request = makeListRequestParams()
    Api().createJobStatus(request).then((data) => {
      pollingJobStatus(data.id)
    }).catch((error) => {
      const res = error.response
      toFalse()
      switch (res.status) {
        case 401:
          loginWithRedirect({ appState: { targetUrl: location.pathname } })
          break
        case 403:
          sensorExport.value = false
          props.showFlash(i18n.global.t('pages.common.messages.forbidden'), "error", true)
          break
        case 422:
          props.showFlash(i18n.global.t('pages.temperatureSensors.charts.errors.download'), "error", true)
      }
    })
  }

  function pollingJobStatus (id) {
    const interval = setInterval(() => {
      Api().getJobStatus(id).then((res) => {
        if (res.status == "completed") {
          download(res.file_path)
          clearInterval(interval)
        }
        if (res.status == "failed") {
          clearInterval(interval)
          toFalse()
        }
      })
    }, 3000)
  }

  function download(path) {
    Api().fileDownload(path)
    toFalse()
  }

  function makeGraphRequestParams() {
    pdfGraphLoading.value = true
    return {
      type: OUTPUT_PDF_TYPE_TEMPERATURE_GRAPH,
      vehicle_id: selectedVehicle.vehicle.id,
      operation_office_id: selectedVehicle.vehicle.operationOfficeId,
      start_date_time: format(viewChartConditions.startTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja }),
      end_date_time: format(viewChartConditions.endTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja }),
      output_time: selectItem.value,
      x_axis_labels: xAxisLabels.value,
      y_axis_labels: yAxisLabels.value,
      ch1_sensors: makeChNames(),
      unit_status: batteryLevel.value
    }
  }

  function makeListRequestParams() {
    pdfListLoading.value = true
    return {
      type: OUTPUT_PDF_TYPE_TEMPERATURE_LIST,
      types: ["sensor"],
      vehicle_id: selectedVehicle.vehicle.id,
      operation_office_id: selectedVehicle.vehicle.operationOfficeId,
      start_date_time: format(viewChartConditions.startTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja }),
      end_date_time: format(viewChartConditions.endTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja }),
    }
  }

  function changePdfBtnState(bool) {
    pdfBtnShoweableState.value = bool
  }

  function changevehicleSelectedState (bool) {
    isSelectedVehicle.value = bool
  }

  function makeChNames() {
    let chNmaes = []
    for (var i = 0; i < legendsStatus.value.length; i++) {
      chNmaes.push(`unit${i + 1}`)
      if (!legendsStatus.value[i]) chNmaes[i] = ""
    }
    return chNmaes
  }

  function updareLoadingState(bool) {
    loading.value = bool
  }

  function errorHandle(res) {
    switch (res.status) {
      case 401:
        loginWithRedirect({ appState: { targetUrl: location.pathname } });
        loading.value = false;
        break;
      case 403:
        props.showFlash(i18n.global.t('pages.common.messages.notEditAuthority'), 'error', false);
        loading.value = false;
        break;
    }
    return loading.value = false;
  }

  function toFalse() {
    pdfGraphLoading.value = false;
    pdfListLoading.value = false;
  }
</script>

<template>
  <div class="d-flex" style="min-height: 50vw;">
    <div class="content p-4 w-100 flex-column">
      <div class="h-100">
        <VehicleFilter
          :vehicleId="queryParamsVehicleId"
          v-model="selectedVehicle"
          :loading="loading"
          :pdfLoading="pdfGraphLoading || pdfListLoading"
          :windowInnerWidth="windowInnerWidth"
          :showableButton="showableButton"
          :showFlash="showFlash"
          @search="searchVehicle"
          @update="changeSelectedVehicle"
          @vehicleSelectedState="changevehicleSelectedState"
          @updateIsQueryParams="updateIsQueryParams"
        />
        <div style="border: black solid 1px;" v-show="isSelectedVehicle">
          <TemperatureReportHeader
            :carNumber="selectedVehicle.vehicle.number_plate"
          />
          <div style="position: relative">
            <SelectOutputTime
              :updateViewConditions="updateViewConditions"
              @loadingState="updareLoadingState"
              @pdfBtnShoweableState="changePdfBtnState"
              @labels="changeChartLabels"
              @updateIsQueryParams="updateIsQueryParams"
              :initialViewChartConditions="viewChartConditions"
              :isQueryParams="isQueryParams"
              :selectedVehicle="selectedVehicle"
              :showFlash="showFlash"
              :sensorSettings="selectedVehicle.vehicle.sensor_setting"
            />
            <CreatePdfButton
              :pdfBtnShoweableState="pdfBtnShoweableState && sensorExport"
              :pdfGraphLoading="pdfGraphLoading"
              :pdfListLoading="pdfListLoading"
              @downloadPdf="downloadPDF"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>
