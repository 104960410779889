<template>
  <div ref="modal_form" class="modal-form">
    <div class="modal-header justify-content-center pt-3">
      <label>{{ label }}</label>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-3">
        <ReadOnlyCompornent
          :readOnly="readOnlyKeys && readOnlyKeys.includes('management_office')"
          :title="i18n.global.t('pages.spot.form.managementOffice')"
          :value="managementOffice?.name"
        />
        <ManagementOfficeAutoComplete
          :class="managementOfficeClass"
          v-model="managementOffice"
          :error-messages="errors.managementOffice"
          selectFirstIfOnlyOne
          :initialManagementId="item.management_officeable_id"
          :initialManagementType="item.management_officeable_type"
          :clearable="managementOfficeClearable"
        />
      </div>
      <div class="col-3">
        <ReadOnlyCompornent
          :readOnly="readOnlyKeys && readOnlyKeys.includes('operation_office')"
          :title="i18n.global.t('pages.spot.form.operationOffice')"
          :value="operationOffice?.name"
        />
        <SingleOperationOfficeAutoComplete
          :class="operationOfficeClass"
          v-model="operationOffice"
          :managementOffice="managementOffice"
          :error-messages="errors.operationOffice"
          selectFirstIfOnlyOne
          :initialOperationId="item.operation_office_id"
          :clearable="operationOfficeClearable"
        />
      </div>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-5">
        <ReadOnlyCompornent
          :readOnly="readOnlyKeys && readOnlyKeys.includes('name')"
          :title="i18n.global.t('pages.spot.name')"
          :value="name"
        >
          <v-text-field
            :clearable="!(readOnlyKeys && readOnlyKeys.includes('name'))"
            :label="i18n.global.t('pages.spot.name')"
            v-model="name"
            hide-details="auto"
            :error-messages="errors?.name"
          />
        </ReadOnlyCompornent>
      </div>
      <div class="col-3">
        <ReadOnlyCompornent
          :readOnly="readOnlyKeys && readOnlyKeys.includes('telephone_number')"
          :title="i18n.global.t('pages.spot.telephoneNumber')"
          :value="form.telephone_number"
        >
          <v-text-field
            :readonly="readOnlyKeys && readOnlyKeys.includes('telephone_number')"
            :clearable="!(readOnlyKeys && readOnlyKeys.includes('telephone_number'))"
            :label="i18n.global.t('pages.spot.telephoneNumber')"
            hide-details="auto"
            v-model="form.telephone_number"
            type="tel"
            :rules="[validateTelephoneNumber]"
            placeholder="07058774589"
            :error-messages="errors?.telephone_number"
          />
          </ReadOnlyCompornent>
      </div>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-3">
        <ReadOnlyCompornent
          :readOnly="readOnlyKeys && readOnlyKeys.includes('radius')"
          :title="i18n.global.t('pages.spot.form.radius')"
          :value="radius"
        >
          <v-text-field
            :clearable="!(readOnlyKeys && readOnlyKeys.includes('radius'))"
            :label="i18n.global.t('pages.spot.form.radius')"
            v-model="radius"
            hide-details="auto"
            :error-messages="errors?.radius"
          />
        </ReadOnlyCompornent>
      </div>
    </div>
    <template v-if="item">
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-8">
          <ReadOnlyCompornent
            :readOnly="readOnlyKeys && readOnlyKeys.includes('address')"
            :title="i18n.global.t('pages.spot.form.location')"
            :value="address"
          >
            <v-text-field
              :clearable="!(readOnlyKeys && readOnlyKeys.includes('address'))"
              prepend-inner-icon="mdi-map-marker"
              :label="i18n.global.t('pages.spot.form.location')"
              v-model="address"
              hide-details="auto"
              :error-messages="errors?.address"
            />
          </ReadOnlyCompornent>
        </div>
        <div class="col-1">
          <v-btn elevation="2" class="exec-btn" @click="handleSearch"
            v-if="!(readOnlyKeys && readOnlyKeys.includes('address'))"
          >
            {{ i18n.global.t('pages.common.button.search') }}
          </v-btn>
        </div>
      </div>
      <SelectAddress
        :readonly="readOnlyKeys && readOnlyKeys.includes('map')"
        :draggable="draggable"
        v-model="form"
        @changeLatlng="changeLatlng"
      />
    </template>
    <div class="row mr-3 ml-3 py-0">
      <small>
        <pre class="m-0">
{{ i18n.global.t('pages.spot.form.notice1') }}
{{ i18n.global.t('pages.spot.form.notice2') }}</pre>
      </small>
    </div>
    <div class="row mr-3 ml-3">
      <div class="col-12"></div>
    </div>
    <Buttons
      @cancel="closeModal"
      @submit="submit"
      :running="running"
      :hasError="hasError"
      :isUpdateAuthority="isUpdateAuthority"
    />
  </div>
</template>

<script>
import 'gitart-vue-dialog/dist/style.css';
import Api from '@/services/api/ApiServiceFabrick';
import Geocoder from '@/services/models/Gercoder';
import Buttons from '@/components/SpotParts/ModalButtons';
import SelectAddress from '@/components/SpotParts/SelectAddress';
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import ReadOnlyCompornent from '@/components/SpotParts/ReadOnlyCompornent.vue';
import i18n from '@/lang/i18n';
import { scrollToErrorComponent } from '@/services/validators';

export default {
  props: {
    item: {},
    label: {
      type: String,
      default: i18n.global.t('pages.spot.form.update'),
    },
    showFlash: {
      type: Function,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isUpdateAuthority: {
      type: Boolean,
    },
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
    readOnlyKeys: {
      type: Array,
      default: null
    },
    draggable: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    form: { id: '' },
    errors: {},
    geocoder: null,
    managementOffice: null,
    operationOffice: null,
    name: null,
    radius: null,
    address: null,
    running: false,
  }),
  setup(props) {
    return {
      i18n,
    }
  },
  mounted() {
    this.mirrorForm();
  },
  computed: {
    managementOfficeClearable() {
      return !this.isSubManagementOffice && !this.isOperationOffice;
    },
    managementOfficeClass() {

      if (this.readOnlyKeys && this.readOnlyKeys.includes('management_office')) {
        return 'd-none';
      } else {
        return '';
      }
    },
    operationOfficeClearable() {
      return !this.isOperationOffice;
    },
    operationOfficeClass() {

      if (this.readOnlyKeys && this.readOnlyKeys.includes('operation_office')) {
        return 'd-none';
      } else {
        return '';
      }
    },
  },
  methods: {
    validate() {
      let invalid = false;
      invalid = !this.validateManagementOffice() || invalid;
      invalid = !this.validateOperationOffice() || invalid;
      invalid = !this.validateName() || invalid;
      invalid = !this.validateTelephoneNumber() || invalid;
      invalid = !this.validateRadius() || invalid;
      invalid = !this.validateAddress() || !this.validatePoint() || invalid;
      return !invalid;
    },
    validateManagementOffice() {
      if (this.form.management_officeable_id) {
        this.errors.managementOffice = [];
        return true;
      } else {
        this.errors.managementOffice = [i18n.global.t('pages.spot.messages.select')];
        return false;
      }
    },
    validateOperationOffice() {
      if (this.form.operation_office_id) {
        this.errors.operationOffice = [];
        return true;
      } else {
        this.errors.operationOffice = [i18n.global.t('pages.spot.messages.select')];
        return false;
      }
    },
    validateName() {
      if (this.form.name) {
        this.errors.name = [];
        return true;
      } else {
        this.errors.name = [i18n.global.t('pages.spot.messages.input')];
        return false;
      }
    },
    validateTelephoneNumber() {
      if (!this.form.telephone_number) {
        this.errors.telephone_number = [];
        return true
      }
      const len = this.form.telephone_number.length;
      if (len < 10 || len > 11 || !this.form.telephone_number.match(/^\d+$/)) {
        this.errors.telephone_number = [i18n.global.t('pages.spot.messages.invalidTelephoneNumber')];
        return false;
      }
      this.errors.telephone_number = [];
      return true;
    },
    validateRadius() {
      this.errors.radius = [];
      if (!this.form.radius) {
        this.errors.radius = [i18n.global.t('pages.spot.messages.input')];
        return false;
      }
      if (!this.form.radius?.match(/^[0-9]+[\\.]?[0-9]*$/)) {
        this.errors.radius = [i18n.global.t('pages.spot.messages.inputNumber')];
        return false;
      }
      if (this.form.radius > 100000) {
        this.errors.radius = [i18n.global.t('pages.spot.messages.inputNumberLessThan100000')];
        return false;
      }
      return true;
    },
    validateAddress() {
      if (!this.form.address) {
        this.errors.address = [i18n.global.t('pages.spot.messages.input')];
        return false;
      }
      this.errors.address = this.errors.address ?? [];
      return true;
    },
    validatePoint() {
      if (!this.form.latitude || !this.form.longitude) {
        this.errors.address = [i18n.global.t('pages.spot.messages.executionSearch')];
        return false;
      }
      this.errors.address = this.errors.address ?? [];
      return true;
    },
    initMap() {
      if (this.geocoder) return;
      this.geocoder = new Geocoder();
    },
    handleSearch() {
      this.initMap()
      this.geocoder.geocode(this.form.address, this.form.latlng).then((position) => {
        this.currentErrors = []
        this.form.latitude = position.lat
        this.form.longitude = position.lng
        this.address = position.address
        this.errors.address = []
      }).catch((err) => {
        console.log(err)
        this.errors.address = [i18n.global.t('pages.spot.messages.addressNotFound')]
        this.form.latitude = null
        this.form.longitude = null
      });
    },
    submit() {
      this.running = true;
      if (!this.validate()) {
        this.running = false;
        this.$refs.modal_form.scrollTop = 0;
        scrollToErrorComponent();
        return;
      }
      let method = Api().createSpot;
      if (this.item.id) {
        method = Api().updateSpot;
      }
      if (!this.form?.id) this.form.id = '';
      method(this.form)
        .then((res) => {
          this.$emit('updateItem', res);
          this.showFlash(i18n.global.t('pages.common.messages.success'), 'success', true);
          this.running = false;
          this.closeModal();
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.showFlash(i18n.global.t('pages.common.messages.failed'), 'error', false);
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              this.closeModal();
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.errors = res.data.errors;
              this.$refs.modal_form.scrollTop = 0;
              scrollToErrorComponent();
          }
          this.running = false;
        });
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    mirrorForm() {
      this.form = JSON.parse(JSON.stringify(this.item));
      if (this.form.management_officeable_id && this.form.management_officeable_type) {
        this.managementOffice = {
          id: this.form.management_officeable_id,
          type: this.form.management_officeable_type,
        }
      }
      if (this.form?.operation_office_id) {
        this.operationOffice = {
          id: this.form?.operation_office_id
        }
      }
      this.name = this.form.name
      this.radius = this.form.radius
      this.address = this.form.address
    },
    changeLatlng(latlng) {
      this.form.latlng = {
        lat: latlng.latitude,
        lng: latlng.longitude,
      }
      this.form.address = ""
      this.handleSearch()
    }
  },
  watch: {
    operationOffice: {
      handler(after, before) {
        this.form.operation_office_id = this.operationOffice?.id;
        if (before?.id || after?.id) this.validateOperationOffice();
      },
      deep: true,
    },
    managementOffice: {
      handler(after, before) {
        this.form.management_officeable_id = this.managementOffice?.id;
        this.form.management_officeable_type = this.managementOffice?.type;
        if (before?.id || after?.id) this.validateManagementOffice();
      },
      deep: true,
    },
    name: {
      handler() {
        if (this.form.name == this.name) return;
        this.form.name = this.name;
        this.validateName();
      },
    },
    radius: {
      handler() {
        if (this.form.radius == this.radius) return;
        this.form.radius = this.radius;
        this.validateRadius();
      },
    },
    address: {
      handler() {
        if (this.form.address == this.address) return
        this.form.address = this.address
        this.errors.address = []
        this.form.latlng = null
        this.validateAddress()
      }
    },
  },
  components: {
    Buttons,
    SelectAddress,
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
    ReadOnlyCompornent,
  },
};
</script>

<style scoped lang="scss">
.modal-form {
  height: auto;
  overflow: auto;
}
.modal-header {
  font-weight: 600;
}
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
</style>
