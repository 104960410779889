import i18n from '@/lang/i18n';

export const ALL = "all"
export const COMPLETED = "only-completed"
export const INCOMPLETED = "only-incompleted"

export const SELECTABLE_STATUSES = [
  {
    key: i18n.global.t('pages.allowAccessOffice.selectableStatuses.all'),
    value: ALL
  },
  {
    key: i18n.global.t('pages.allowAccessOffice.selectableStatuses.completed'),
    value: COMPLETED
  },
  {
    key: i18n.global.t('pages.allowAccessOffice.selectableStatuses.incompleted'),
    value: INCOMPLETED
  }
]
