
import ApiProvider from "@/services/api/providers/AutoCompletes/ApiProvider";

import getDailyReportLayoutResponse from "@/types/responses/AutoCompletes/getDailyReportLayout"
import getApiDailyReportLayoutResponse from "@/types/apiresponses/getApiDailyReportLayout"
import getDeviceResponse from "@/types/responses/getDevice"
import getVehicleResponse from "@/types/responses/getVehicle"

class AutoComplete implements ApiProvider {
  public async dailyReportLayouts():Promise<getDailyReportLayoutResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public devices(params = {}, cache = false): Promise<getDeviceResponse[]>  {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public vehicles(params = {}, cache = false): Promise<getVehicleResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public temperatureSensorVehicles(cache = false): Promise<getVehicleResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
}
export default AutoComplete;
