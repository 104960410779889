import i18n from '@/lang/i18n';

export const SELECTABLE_STATUSES = [
  {
    key: i18n.global.t('pages.unexpected_event_and_alert_statuses.filter.selectDivision.all'),
    value: 'all'
  },
  {
    key: i18n.global.t('pages.unexpected_event_and_alert_statuses.filter.selectDivision.unexpectedEvent'),
    value: 'unexpected_events'
  },  
  {
    key: i18n.global.t('pages.unexpected_event_and_alert_statuses.filter.selectDivision.longTimeContinuousDriving'),
    value: 'long_time_continuous_driving_extensions'
  },
]
