import i18n from '@/lang/i18n';

export const DEFAULT_ITTENKO_GET_HOURS = 1;
export const DEFAULT_ITTENKO_GET_MINUTES = 0;

export const OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST = [
  { label: '日', value: 'Sun' },
  { label: '月', value: 'Mon' },
  { label: '火', value: 'Tue' },
  { label: '水', value: 'Wed' },
  { label: '木', value: 'Thu' },
  { label: '金', value: 'Fri' },
  { label: '土', value: 'Sat' }
];

export const OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST = [
  { key: -2, label: i18n.global.t('pages.operationOffices.form.reportDeadLineDay.twoDayBefore') },
  { key: -1, label: i18n.global.t('pages.operationOffices.form.reportDeadLineDay.oneDayBefore') },
  { key: 0, label: i18n.global.t('pages.operationOffices.form.reportDeadLineDay.endOfTheMonth') },
];

export const OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON = {
  color_setting_monthly: "0",
  color_setting: {
    1: {
      alert_lv1_color: "#000000",
      alert_lv1_time: "",
      alert_lv2_color: "#000000",
      alert_lv2_time: "",
      alert_lv3_color: "#000000",
      alert_lv3_time: ""
    }
  },
  bound_time_by_days: null,
  bound_time_by_hours: null,
  over_time_thresholds_type: "0",
  over_time_thresholds: {
    daily: null
  },
  over_time_working_hour_thresholds: {},
  csv_date_format: "Y/m/d H:i:s",
  report_deadline_day: 0
}

export const OPERATION_OFFICE_DEFAULT_FULL_COLOR_SETTING = {
  1: {
    alert_lv1_color: '#000000',
    alert_lv1_time: '',
    alert_lv2_color: '#000000',
    alert_lv2_time: '',
    alert_lv3_color: '#000000',
    alert_lv3_time: '',
  },
  2: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  3: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  4: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  5: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  6: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  7: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  8: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  9: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  10: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  11: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  },
  12: {
    alert_lv1_color: '#000000',
    alert_lv1_time: null,
    alert_lv2_color: '#000000',
    alert_lv2_time: null,
    alert_lv3_color: '#000000',
    alert_lv3_time: null,
  }
}

export const INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL = {
  id: null,
  operation_office_id: null,
  email: null
}

export const SENSOR_ALERT_BATTERY_LEVEL = [
  {key: '10%', value: 10},
  {key: '20%', value: 20},
  {key: '30%', value: 30},
  {key: '40%', value: 40},
  {key: '50%', value: 50},
  {key: '60%', value: 60},
  {key: '70%', value: 70},
  {key: '80%', value: 80},
  {key: '90%', value: 90}
]
