<template>
  <template v-if="dialogState">
  <GDialog
    ref="spot_modal"
    v-model="dialogState"
    max-width="90%"
    transition="custom-from-bottom-transition"
  >

  <Form
    v-model="dialogState"
    :item="item"
    :showFlash="showFlash"
    @updateItem="updateItem"
    :isUpdateAuthority="isUpdateAuthority"
    :isOperationOffice="isOperationOffice"
    :isSubManagementOffice="isSubManagementOffice"
  />
  </GDialog>
  </template>
<slot name="button" :dialogState="dialogState">
  <button @click="dialogState = true" class="material-symbols-outlined">
    {{icon}}
  </button>
</slot>

</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import Buttons from '@/components/SpotParts/ModalButtons'
import Form from '@/components/SpotParts/SpotUpdateModalForm'
import { useAuth } from "@/services/user/Auth0UserProvider"
import i18n from '@/lang/i18n';

export default {
  setup() {
    const { loginWithRedirect } = useAuth();
    return {
      loginWithRedirect,
      i18n
    }
  },
  props: {
    showFlash: {
      type: Function,
    },
    item: {},
    icon: {
      type:String,
      default: 'edit_note',
    },
    label: {
      type: String,
      default: i18n.global.t('pages.spot.form.update'),
    },
    isUpdateAuthority: {
      type: Boolean
    },
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogState: false,
  }),
  methods: {
    updateItem(res) {
      this.$emit('updateItem', res)
    }
  },
  watch: {
    dialogState: {
      handler() {
        if (this.dialogState) {
          this.form = JSON.parse(JSON.stringify(this.item));
          this.$emit('change', this.form)
        }
      }
    }
  },
  components: {
    GDialog,
    Form,
  },
}
</script>

<style>
</style>
