<template>
  <div style="min-height: 45vw">
    <div class="d-flex justify-content-between mb-1">
      <h4 class="content pl-3 w-100">{{ title }}</h4>
      <div class="pr-3 align-self-center" style="white-space: nowrap">
        <ManualLink :pageName="pageName" />
      </div>
    </div>
    <ExternalUrlsForm
      :externalUrls="response.operation_office?.external_urls"
      @inputValue="updateExternalUrls"
      :errors="response.errors ?? []"
      :windowInnerWidth="windowInnerWidth"
    />
    <IttenkoForm
      :data="response.operation_office"
      @inputValue="updateOperationOffice"
      :errors="response.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <TdsensorForm
      :data="response.operation_office"
      @inputValue="updateOperationOffice"
      :errors="response.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <SensorAlertSettings
      v-if="response.operation_office.tdsensor"
      v-model="response.operation_office"
      :windowInnerWidth="windowInnerWidth"
      :errorMessages="response.errors"
    />
    <AllocatedWorkFrom
      :allocated_minute="response.operation_office?.allocated_minute"
      :allocated_work="response.operation_office?.allocated_work"
      @inputValue="updateOperationOffice"
      :errors="response?.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <AlertSettingForm
      :settingsMonthlyReport="
        response.operation_office?.settings_monthly_report
      "
      @inputOrChange="updateOperationOfficeAssociation"
      :errors="response.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <MaximumWorkingHoursForm
      :settingsMonthlyReport="
        response.operation_office?.settings_monthly_report
      "
      @selected="updateSettingsMonthlyReportOverTime"
      @inputTime="updateOperationOfficeAssociation"
      :errors="response.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <OvertimeWorkHourLimitForm
      :settingsMonthlyReport="
        response.operation_office?.settings_monthly_report
      "
      @inputTime="updateOperationOfficeAssociation"
      :errors="response.errors"
      :windowInnerWidth="windowInnerWidth"
    />
    <RadioForm
      :title="i18n.global.t('pages.operationOffices.form.reckonedDate')"
      :items="OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST"
      emitKey="weekly_aggregation"
      :selectedItem="response.operation_office?.weekly_aggregation"
      cols="6"
      @selected="updateOperationOffice"
      :windowInnerWidth="windowInnerWidth"
      :errors="response.errors['weekly_aggregation']"
    />
    <SelectForm
      :title="i18n.global.t('pages.operationOffices.form.reportDeadLineDay.title')"
      :items="dayList"
      emitKey="report_deadline_day"
      :selectedItem="
        response.operation_office?.settings_monthly_report?.report_deadline_day
      "
      @selected="updateOperationOfficeAssociation"
      :errors="response.errors?.['settings_monthly_report.report_deadline_day']"
      :windowInnerWidth="windowInnerWidth"
    />
    <SelectForm
      :title="i18n.global.t('pages.operationOffices.form.cooperationList.title')"
      :items="cooperationList"
      emitKey="cooperation"
      :selectedItem="
        response.operation_office?.settings_monthly_report?.cooperation
      "
      @selected="updateSettingsMonthlyReportCooperation"
      :windowInnerWidth="windowInnerWidth"
    />
    <template v-if="params?.settings_monthly_report?.cooperation == 'ittenko_cooperation'">
      <IttenkoTimesForm
        v-model="ittenkoTimes"
        :errors="response.errors"
        :windowInnerWidth="windowInnerWidth"
      />
    </template>
    <SelectForm
      :title="i18n.global.t('pages.operationOffices.form.csvDateFormat.title')"
      :items="formatList"
      emitKey="csv_date_format"
      :selectedItem="
        response.operation_office?.settings_monthly_report?.csv_date_format
      "
      @selected="updateOperationOfficeAssociation"
      :windowInnerWidth="windowInnerWidth"
    />
    <RadioForm
      :title="i18n.global.t('pages.operationOffices.form.viewThreshold.title')"
      :items="viewThreshold"
      emitKey="view_threshold"
      cols="4"
      :selectedItem="response.operation_office?.view_threshold"
      @selected="updateOperationOffice"
      :windowInnerWidth="windowInnerWidth"
    />
    <ImprinterNameForm
      :imprinterNames="response.operation_office?.imprinter_name"
      @inputImprinterName="updateOperationOffice"
      :windowInnerWidth="windowInnerWidth"
    />
    <CommentForm
      :comment="response.operation_office?.comment"
      @inputComment="updateOperationOffice"
      :errors="response.errors?.comment"
      :windowInnerWidth="windowInnerWidth"
    />
    <div class="mt-4 mb-4 mr-4" style="text-align: right">
      <v-btn
        v-if="updateAuthority"
        style="
          color: white;
          font-weight: bold;
          background-color: rgb(21, 130, 156);
        "
        @click="submit"
        :loading="isUpdating"
      >
        {{ i18n.global.t('pages.common.button.conservation') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import Api from "@/services/api/ApiServiceFabrick";
import ExternalUrlsForm from "@/components/OperationOfficeForms/ExternalUrlsForm";
import SelectForm from "@/components/OperationOfficeForms/SelectForm";
import RadioForm from "@/components/OperationOfficeForms/RadioForm";
import AlertSettingForm from "@/components/OperationOfficeForms/AlertSettingForm";
import MaximumWorkingHoursForm from "@/components/OperationOfficeForms/MaximumWorkingHoursForm";
import OvertimeWorkHourLimitForm from "@/components/OperationOfficeForms/OvertimeWorkHourLimitForm";
import ImprinterNameForm from "@/components/OperationOfficeForms/ImprinterNameForm";
import CommentForm from "@/components/OperationOfficeForms/CommentForm";
import IttenkoForm from "@/components/OperationOfficeForms/IttenkoForm";
import TdsensorForm from "@/components/OperationOfficeForms/TdsensorForm";
import AllocatedWorkFrom from "@/components/OperationOfficeForms/AllocatedWorkFrom";
import ManualLink from "@/components/ManualLink";
import IttenkoTimesForm from '@/components/OperationOfficeForms/IttenkoTimesForm.vue';
import SensorAlertSettings from '@/components/OperationOfficeForms/SensorAlertSettings.vue';
import { INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL, SENSOR_ALERT_BATTERY_LEVEL } from '@/services/consts/operation_office';
import { useAuth } from "@/services/user/Auth0UserProvider";
import { reactive, ref, onBeforeUnmount } from "vue";
import { scrollToErrorComponent } from "@/services/validators";
import {
  OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST,
  OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST,
  OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON,
  DEFAULT_ITTENKO_GET_HOURS,
  DEFAULT_ITTENKO_GET_MINUTES
} from "@/services/consts";
import i18n from '@/lang/i18n';

export default {
  props: {
    showFlash: { type: Function },
    switchContentLoading: { type: Function },
  },
  setup(props) {
    const { loginWithRedirect } = useAuth();
    const title = i18n.global.t('pages.operationOffices.title.edit');
    const response = reactive({
      operation_office: {},
      management_office_accounts: [],
      errors: {},
    });
    const isUpdating = ref(false);
    const updatable = ref(false);
    const pageName = "manageOperationOffice";
    const params = ref();
    const updateAuthority = ref(true);

    const ittenkoTimes = ref({
      ittenko_before_leaving: {
        hours: DEFAULT_ITTENKO_GET_HOURS,
        minutes: DEFAULT_ITTENKO_GET_MINUTES,
      },
      ittenko_after_returning: {
        hours: DEFAULT_ITTENKO_GET_HOURS,
        minutes: DEFAULT_ITTENKO_GET_MINUTES
      }
    });
    const initialSensorAlertSetting = {
      battery_alert: SENSOR_ALERT_BATTERY_LEVEL[0]
    }

    const getAuthorityList = async(isError = false) => {
      if (isError) await AuthorityCacheApi.reflesh()
      const authorityList = await AuthorityCacheApi.getWithParse()
      updateAuthority.value = !!authorityList["octlink.operation_office.update"]
    }

    const errorHandle = (res) => {
      switch (res.status) {
        case 401:
          loginWithRedirect({ appState: { targetUrl: location.pathname } });
          break;
        case 403:
          alertDisplay(i18n.global.t('pages.common.messages.notEditAuthority'))
          getAuthorityList(true)
          break;
        case 422:
          response.errors = res.data.errors;
          scrollToErrorComponent();
      }
      return;
    };

    (async () => {
      props.switchContentLoading(true);
      getAuthorityList()
      document.title = title;
      const managementOfficeAccounts = await Api()
        .getManagemnetOfficeAccounts()
        .catch((error) => {
          props.switchContentLoading(false);
          return errorHandle(error.response);
        });

      if (managementOfficeAccounts) {
        response.management_office_accounts = managementOfficeAccounts;
      }

      const operationOfficeWithAssociation = await Api()
        .getOperationOfficeWithAssociation()
        .catch((error) => {
          props.switchContentLoading(false);
          return errorHandle(error.response);
        });

      if (operationOfficeWithAssociation[0]["ittenko_failed_at"]) {
        alertDisplay(i18n.global.t('pages.operationOffices.form.message.hasIttenkoFailedAt'))
      }

      if (operationOfficeWithAssociation) {
        operationOfficeWithAssociation[0]["settings_monthly_report"] =
          JSON.parse(
            operationOfficeWithAssociation[0]["settings_monthly_report"]
          );
        operationOfficeWithAssociation[0]["imprinter_name"] = JSON.parse(
          operationOfficeWithAssociation[0]["imprinter_name"]
        );

        if (!operationOfficeWithAssociation[0]["settings_monthly_report"]) {
          operationOfficeWithAssociation[0] = settingInitialValues(operationOfficeWithAssociation[0])
        }

        if (
          !operationOfficeWithAssociation[0]["settings_monthly_report"][
            "view_1day_unit"
          ]
        ) {
          operationOfficeWithAssociation[0]["settings_monthly_report"][
            "view_1day_unit"
          ] = "0";
        }

        response.operation_office = operationOfficeWithAssociation[0];
        if (response.operation_office.external_urls.length == 0) {
          response.operation_office.external_urls = [{ name: "", url: "" }];
        }
        params.value = response.operation_office;
        response.operation_office.sensor_notification_battery_level = SENSOR_ALERT_BATTERY_LEVEL.find((battery) => {
          return battery.value == params.value.sensor_notification_battery_level
        })

        if (!response.operation_office.weekly_aggregation) {
          alertDisplay(i18n.global.t('pages.operationOffices.form.message.isNotReckonedDate'))
        }

        ittenkoTimes.value = {
          ittenko_before_leaving: {
            hours: response.operation_office.ittenko_before_leaving_hours,
            minutes: response.operation_office.ittenko_before_leaving_minutes
          },
          ittenko_after_returning: {
            hours: response.operation_office.ittenko_after_returning_hours,
            minutes: response.operation_office.ittenko_after_returning_minutes
          }
        }

        if (!response.operation_office.sensor_alert_notification_mails.length) {
          response.operation_office.sensor_alert_notification_mails[0] = INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL
        }

        props.switchContentLoading(false);
      }

      //値がない場合は初期値を設定する。
      if (
        !params.value?.["settings_monthly_report"]?.[
          "enable_set_start_and_end_of_working_time"
        ]
      ) {
        params.value["settings_monthly_report"][
          "enable_set_start_and_end_of_working_time"
        ] = "0";
      }
    })();

    const formatList = [
      { key: "Y/m/d H:i:s", label: "YYYY/MM/DD HH:mm:ss" },
      { key: "Y-m-d H:i:s", label: "YYYY-MM-DD HH:mm:ss" },
      { key: "Ymd H:i:s", label: "YYYYMMDD HH:mm:ss" },
    ];

    const dayList = [];

    for (let i = 1; i < 29; i++) dayList.push({ key: i, label: i });

    OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST.forEach((date) => {
      dayList.push(date);
    })

    const viewThreshold = [
      { label: i18n.global.t('pages.operationOffices.form.viewThreshold.display'), value: "1" },
      { label: i18n.global.t('pages.operationOffices.form.viewThreshold.hidden'), value: "0" },
    ];

    const cooperationList = [
      { key: null, label: "" },
      { key: "ittenko_cooperation", label: i18n.global.t('pages.operationOffices.form.cooperationList.ittenko') },
    ];

    const settingInitialValues = (operationOfficeWithAssociation) => {
      operationOfficeWithAssociation['settings_monthly_report'] = JSON.parse(OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON)
      operationOfficeWithAssociation['view_threshold'] = '0'
      operationOfficeWithAssociation['external_urls'] = [{ name: "", url: "" }]
      operationOfficeWithAssociation['imprinter_name'] = ['', '', '']
      operationOfficeWithAssociation['tracking_service'] = 'docomap'
      operationOfficeWithAssociation['allowed_ips'] = []

      return operationOfficeWithAssociation
    }

    const updateOperationOfficeAssociation = (key, value) => {
      if (Array.isArray(value)) {
        params.value[key] = value;
      } else {
        const nestKey = Object.keys(value)[0];
        params.value[key][nestKey] = value[nestKey];
      }
    };

    const updateExternalUrls = (officeName) => {
      Object.keys(officeName).forEach((key) => {
        params.value[key] = officeName[key];
      });
    };

    const updateOperationOffice = (obj) => {
      const key = Object.keys(obj)[0];
      params.value[key] = obj[key];
    };

    const updateSettingsMonthlyReport = (obj) => {
      const key = Object.keys(obj)[0];
      params.value["settings_monthly_report"][key] = obj[key];
    };

    const updateSettingsMonthlyReportCooperation = (_, obj) => {
      const key = Object.keys(obj)[0];
      params.value["settings_monthly_report"][key] = obj[key];
    };

    const updateSettingsMonthlyReportOverTime = (type, list) => {
      params.value["settings_monthly_report"]["over_time_thresholds_type"] =
        type;
      params.value["settings_monthly_report"]["over_time_thresholds"] = list;
    };

    const updateIpKeepsEffectiveWithIps = (ipKeepsEffective, allowedIps) => {
      params.value["ip_keeps_effective"] = ipKeepsEffective;
      params.value["allowed_ips"] = allowedIps;
    };

    const makeRequestParams = () => {
      const zipcode = params.value["zipcode"];
      let splitZipcode = [];
      if (zipcode) splitZipcode = zipcode.split("-");

      return {
        id: params.value["id"],
        management_officeable_id: params.value["management_officeable_id"],
        management_officeable_type: params.value["management_officeable_type"],
        work_status: params.value["work_status"],
        tracking_service: params.value["tracking_service"],
        external_urls: params.value["external_urls"],
        operation_office_name: params.value["operation_office_name"],
        operation_office_name_kana: params.value["operation_office_name_kana"],
        contact_name: params.value["contact_name"],
        province: params.value["province"],
        address1: params.value["address1"],
        address2: params.value["address2"],
        telephone_number: params.value["telephone_number"],
        fax_number: params.value["fax_number"],
        email: params.value["email"],
        password_expire_days: params.value["password_expire_days"] ?? 0,
        is_active_password_expire_days: !!params.value["password_expire_days"],
        login_error_limit_times: params.value["login_error_limit_times"] ?? 0,
        is_active_login_error_limit_times: !!params.value["login_error_limit_times"],
        allocated_minute: params.value["allocated_minute"],
        allocated_work: params.value["allocated_work"],
        is_active_allocated: params.value["is_active_allocated"],
        ip_keeps_effective: !!params.value["allowed_ips"],
        ip_address_list: Object.values(params.value["allowed_ips"]) ?? [],
        zipcode: zipcode,
        zip1: splitZipcode[0] ?? null,
        zip2: splitZipcode[1] ?? null,
        comment: params.value["comment"],
        imprinter_name: params.value["imprinter_name"],
        settings_monthly_report: {
          color_setting_monthly:
            params.value["settings_monthly_report"]["color_setting_monthly"],
          color_setting:
            params.value["settings_monthly_report"]["color_setting"],
          bound_time_by_days:
            params.value["settings_monthly_report"]["bound_time_by_days"],
          bound_time_by_hours:
            params.value["settings_monthly_report"]["bound_time_by_hours"],
          over_time_thresholds_type:
            params.value["settings_monthly_report"][
              "over_time_thresholds_type"
            ],
          over_time_thresholds:
            params.value["settings_monthly_report"]["over_time_thresholds"],
          over_time_working_hour_thresholds:
            params.value["settings_monthly_report"][
              "over_time_working_hour_thresholds"
            ],
          csv_date_format:
            params.value["settings_monthly_report"]["csv_date_format"],
          report_deadline_day:
            params.value["settings_monthly_report"]["report_deadline_day"],
          view_1day_unit:
            params.value["settings_monthly_report"]["view_1day_unit"],
          enable_set_start_and_end_of_working_time:
            params.value["settings_monthly_report"][
              "enable_set_start_and_end_of_working_time"
            ],
          cooperation: params.value["settings_monthly_report"]["cooperation"],
        },
        view_threshold: !!Number(params.value["view_threshold"]),
        weekly_aggregation:
          params.value["weekly_aggregation"] != "null"
            ? params.value["weekly_aggregation"]
            : null,
        is_active_ittenko: params.value["is_active_ittenko"],
        tenko_type: params.value["tenko_type"],
        ittenko_secret_key: params.value["ittenko_secret_key"],
        ittenko_company_id: params.value["ittenko_company_id"],
        tdsensor: params.value["tdsensor"],
        ittenko_before_leaving_hours: ittenkoTimes.value.ittenko_before_leaving.hours,
        ittenko_before_leaving_minutes: ittenkoTimes.value.ittenko_before_leaving.minutes,
        ittenko_after_returning_hours: ittenkoTimes.value.ittenko_after_returning.hours,
        ittenko_after_returning_minutes: ittenkoTimes.value.ittenko_after_returning.minutes,
        sensor_notification_battery_level: response.operation_office.sensor_notification_battery_level.value,
        sensor_alert_notification_mail:
          params.value["tdsensor"]
            ? response.operation_office.sensor_alert_notification_mails[0].email
            : '',
      };
    };

    const alertDisplay = (message) => {
      props.showFlash(message, "error", false);
    }

    const submit = () => {
      const requestParams = makeRequestParams();
      isUpdating.value = true;
      Api()
        .updateOperationOfficeWithAssociation(requestParams)
        .then((data) => {
          isUpdating.value = false;
          props.showFlash(i18n.global.t('pages.common.messages.success'), "success", true);
          response.errors = {};
          ittenkoTimes.value = {
            ittenko_before_leaving: {
              hours: data[0].ittenko_before_leaving_hours,
              minutes: data[0].ittenko_before_leaving_minutes
            },
            ittenko_after_returning: {
              hours: data[0].ittenko_after_returning_hours,
              minutes: data[0].ittenko_after_returning_minutes
            }
          }

        })
        .catch((error) => {
          errorHandle(error.response);
          isUpdating.value = false;
        });
    };

    const windowInnerWidth = ref(window.innerWidth);
    const handleResize = () => {
      windowInnerWidth.value = window.innerWidth;
    };

    window.addEventListener("resize", handleResize);

    onBeforeUnmount(() => {
      props.switchContentLoading(false);
    });

    return {
      title,
      response,
      formatList,
      dayList,
      viewThreshold,
      cooperationList,
      isUpdating,
      updateAuthority,
      updateOperationOfficeAssociation,
      updateOperationOffice,
      updateSettingsMonthlyReport,
      updateSettingsMonthlyReportCooperation,
      updateExternalUrls,
      updateSettingsMonthlyReportOverTime,
      updateIpKeepsEffectiveWithIps,
      submit,
      windowInnerWidth,
      pageName,
      params,
      OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST,
      OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST,
      i18n,
      ittenkoTimes,
      DEFAULT_ITTENKO_GET_HOURS,
      DEFAULT_ITTENKO_GET_MINUTES,
    };
  },
  components: {
    ExternalUrlsForm,
    SelectForm,
    RadioForm,
    AlertSettingForm,
    MaximumWorkingHoursForm,
    OvertimeWorkHourLimitForm,
    ImprinterNameForm,
    CommentForm,
    IttenkoForm,
    TdsensorForm,
    ManualLink,
    AllocatedWorkFrom,
    IttenkoTimesForm,
    SensorAlertSettings,
  },
};
</script>
