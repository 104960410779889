export default {
  documentTitle: "運転スコア",
  title: "運転スコア設定",
  form : {
    scoreJudgmentSettings: "スコア判定設定",
    rank: "ランク",
    upperLimit: "上限",
    lowerLimit: "下限",
    judge: "判定",
    point: "点",
    deductionSettings: "減点設定",
    speedDeduction: "速度減点",
    decelerationDeduction: "減速減点",
    accelerationDeduction: "加速減点",
    turningDeduction: "旋回減点",
    alertDetection: "アラート検知",
    secondsOverSpeed: "速度超過秒数",
    pointsDeduction: "点 減点",
    inTimes: "回で",
    inSeconds: "秒で"
  }
}
