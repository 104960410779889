<script setup>
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';
import ChannelRow from '@/components/VehicleFormParts/IniFileTdSensorSettingChannelRow';
import SensorSetting from '@/components/VehicleFormParts/SensorSetting.vue';
import { ref, defineProps, defineEmits, watch, computed } from 'vue';
import i18n from '@/lang/i18n';

const props = defineProps([
  'selectedOperationOffice',
  'modelValue',
  'formInputs',
  'errors',
]);
const emit = defineEmits(['update:modelValue']);
const form = ref(props.modelValue);
const tdrfsensorList = ['ON', 'OFF'];
const tdTypeList = [
  { title: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.tdTypeRf'), value: 'RF' },
  { title: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.tdTypeWired'), value: 'WIRED' },
]
const tdrfbandList = [0, 3];
const tdrfchList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const errorKeyPrefix = 'initial_params.tdsensor';
const settingKeys = {
  tdrfband: 'band',
  tdrfid: 'id',
  tdrfch: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.ch'),
};
const thresholdKeys = {
  tds1: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.ch1'),
  tds2: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.ch2'),
  tds3: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.ch3'),
  tds4: i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.ch4'),
};

watch(
  props,
  () => {
    form.value = props.modelValue ?? {};
    if (
      props.formInputs?.form?.tdsensor?.tdrfsensor !== undefined &&
      props.modelValue?.tdrfsensor === undefined
    ) {
      form.value.tdrfsensor = 'OFF';
    }
    if (
      props.formInputs?.form?.tdsensor?.tdrfband !== undefined &&
      props.modelValue?.tdrfband === undefined
    ) {
      form.value.tdrfband = 0;
    }
    if (
      props.formInputs?.form?.tdsensor?.tdrfch !== undefined &&
      props.modelValue?.tdrfch === undefined
    ) {
      form.value.tdrfch = 0;
    }
    Object.keys(thresholdKeys).forEach((key) => {
      if (props.modelValue[key] === undefined) {
        form.value[key] = 'OFF';
      }
    });
  },
  { deep: true }
);

watch(
  form,
  () => {
    emit('update:modelValue', form.value);
  },
  { deep: true }
);

const getShowAndRequiredAndErrors = (parentKey, formKeys) => {
  if (!props.formInputs?.form[parentKey]) {
    return {
      'isShow': false,
      'isRequired': false,
      'errors': null
    };
  }

  const keys = Object.keys(props.formInputs.form[parentKey]);

  const result = formKeys.map(formKey => {
    return {
      'isShow': keys.includes(formKey),
      'isRequired': !!props.formInputs.form[parentKey][formKey]?.required,
      'errors': props.errors[errorKeyPrefix + '.' + formKey]
    };
  });

  return {
    'isShow': result.every(item => item.isShow),
    'isRequired': result.every(item => item.isRequired),
    'errors': result.map(item => item.errors).filter(error => error)
  };
}

const formFieldOptions = computed(() => {
  const parentKey = 'tdsensor'
  return {
    tdrfid: getShowAndRequiredAndErrors(parentKey, ['tdrfid']),
  };
})
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>

<template>
  <template
    v-if="
      !!props.selectedOperationOffice?.tdsensor && !!formInputs?.form?.tdsensor
    "
  >
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">
        {{ i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.title') }}
      </v-card-title>
      <v-card-text>
        <div class="row mr-3 pb-0">
          <div
            class="col-6"
            v-if="formInputs?.form?.tdsensor?.tdrfsensor != undefined"
          >
            <v-select
              class="small mt-2"
              :label="i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.tdrfSensor')"
              v-model="form.tdrfsensor"
              :items="tdrfsensorList"
              :error-messages="errors[errorKeyPrefix + '.tdrfsensor']"
            >
            </v-select>
          </div>
          <div
            class="col-6"
            v-if="formInputs?.form?.tdsensor?.tdrfsensor != undefined"
          >
            <v-select
              class="small mt-2"
              :label="i18n.global.t('pages.vehicles.form.iniFileSetting.tdSensor.tdTypeTitle')"
              v-model="form.tdtype"
              :items="tdTypeList"
              :readonly="form.tdrfsensor == 'OFF'"
              :disabled="form.tdrfsensor == 'OFF'"
              :error-messages="errors[errorKeyPrefix + '.tdtype']"
            />
          </div>
        </div>
        <div class="row mr-3 pb-0 pt-0">
          <div
            class="col-6"
            v-if="formInputs?.form?.tdsensor?.tdrfband != undefined"
          >
            <v-select
              class="small mt-2"
              :label="settingKeys['tdrfband']"
              v-model="form.tdrfband"
              :items="tdrfbandList"
              :readonly="form.tdtype == 'WIRED'"
              :disabled="form.tdtype == 'WIRED'"
              :error-messages="errors[errorKeyPrefix + '.tdrfband']"
            />
          </div>
          <Input
            v-if="formInputs?.form?.tdsensor?.tdrfid != undefined"
            :label="settingKeys['tdrfid']"
            v-model="form.tdrfid"
            :isShow="formFieldOptions.tdrfid.isShow"
            :isRequired="formFieldOptions.tdrfid.isRequired"
            :formInputs="formInputs"
            :readonly="form.tdtype == 'WIRED'"
            :disabled="form.tdtype == 'WIRED'"
            :errors="formFieldOptions.tdrfid.errors"
          />
          <div class="col-6" v-if="formInputs?.form?.tdsensor?.tdrfch != undefined">
            <v-select
              class="small mt-2"
              :label="settingKeys['tdrfch']"
              v-model="form.tdrfch"
              :items="tdrfchList"
              :readonly="form.tdtype == 'WIRED'"
              :disabled="form.tdtype == 'WIRED'"
              :error-messages="errors[errorKeyPrefix + '.tdrfch']"
            />
          </div>
        </div>
        <div class="row mr-3 pb-4 pt-0">
          <template v-for="(label, key) in thresholdKeys" v-bind:key="key">
            <ChannelRow
              v-model="form"
              :channelKey="key"
              :channelLabel="label"
              :formInputs="formInputs"
              :errors="errors"
            />
          </template>
        </div>
        <SensorSetting
          v-model="form.sensor_setting"
          :errors="props.errors"
        />
      </v-card-text>
    </v-card>
  </template>
</template>
