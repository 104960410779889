<template>
  <div class="spot-list">
    <template v-if="listLoading">
      <div class="not-searched">
        <label>{{ i18n.global.t('pages.common.list.reading') }}</label>
      </div>
    </template>
    <div class="table-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th class="col-md-1" >{{ i18n.global.t('pages.spot.list.id') }}</th>
            <th class="col-md-3" >{{ i18n.global.t('pages.spot.name') }}</th>
            <th class="col-md-1" >{{ i18n.global.t('pages.spot.telephoneNumber') }}</th>
            <th class="col-md-4" >{{ i18n.global.t('pages.spot.address') }}</th>
            <th class="col-md-1" >{{ i18n.global.t('pages.spot.list.range') }}</th>
            <th class="col-md-1" >{{ i18n.global.t('pages.spot.list.updateAt') }}</th>
            <th class="col-md-1" >{{ i18n.global.t('pages.spot.list.edit') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
          <tr
            v-for="item in items"
            :key="'spot_id_' + item?.id"
          >
            <td> {{item.id}}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.telephone_number }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.radius }}</td>
            <td>{{ item.updated_at?.split(" ")[0] }}</td>
            <td class="d-flex">
              <update_modal
                :item="item"
                @updateItem="updateItem"
                :showFlash="showFlash"
                :label="i18n.global.t('pages.spot.form.update')"
                :isUpdateAuthority="isUpdateAuthority"
                :isOperationOffice="isOperationOffice"
                :isSubManagementOffice="isSubManagementOffice"
              />
              <delete_modal
                v-if="isdeleteAuthority"
                :item="item"
                isDeleteAuthority="isdeleteAuthority"
                @change="updateItem"
                :showFlash="showFlash"
              />
            </td>
          </tr>
          </template>
          <template v-else-if="!listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import UpdateModal from "@/components/SpotParts/SpotUpdateModal"
import DeleteModal from "@/components/SpotParts/SpotDeleteModal"
import i18n from '@/lang/i18n';
  export default {
    props: {
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      showFlash: {
        type: Function
      },
      isdeleteAuthority: {
        type: Boolean
      },
      isUpdateAuthority: {
        type: Boolean
      },
      isOperationOffice: {
        type: Boolean,
        default: false,
      },
      isSubManagementOffice: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      return {
        i18n,
      }
    },
    data() {
      return {
        form: {},
      }
    },
    computed: {
    },
    methods: {
      updateItem(item) {
        this.$emit('changeItem')
      },
    },
    watch: {
    },
    components: {
      update_modal: UpdateModal,
      delete_modal: DeleteModal,
    }
  }

</script>

<style>
.spot-list .v-table__wrapper{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
</style>
<style scoped>
.spot-list {
  position: relative;
}
.spot-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  min-width: 7em;
}
.spot-list table .fix-td {
  background-color: white;
  position: sticky;
  left: 0;
}
.spot-list table .table-header-row th {
  z-index: 1;
}
.spot-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
</style>
