<script setup>
import Api from '@/services/api/ApiServiceFabrick';
import AuthorityCacheApi from '@/services/models/AuthorityCacheApi';
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import ExternalUrlsForm from '@/components/OperationOfficeForms/ExternalUrlsForm';
import TrackingServiceForm from '@/components/OperationOfficeForms/TrackingServiceForm';
import OperationOfficeNameForm from '@/components/OperationOfficeForms/OperationOfficeNameForm';
import SalesforceIdForm from '@/components/OperationOfficeForms/SalesforceIdForm';
import ContactNameForm from '@/components/OperationOfficeForms/ContactNameForm';
import AddressForm from '@/components/OperationOfficeForms/AddressForm';
import TelephoneNumberForm from '@/components/OperationOfficeForms/TelephoneNumberForm';
import EmailForm from '@/components/OperationOfficeForms/EmailForm';
import AllocatedWorkFrom from '@/components/OperationOfficeForms/AllocatedWorkFrom';
import SelectForm from '@/components/OperationOfficeForms/SelectForm';
import RadioForm from '@/components/OperationOfficeForms/RadioForm';
import AlertSettingForm from '@/components/OperationOfficeForms/AlertSettingForm';
import MaximumWorkingHoursForm from '@/components/OperationOfficeForms/MaximumWorkingHoursForm';
import OvertimeWorkHourLimitForm from '@/components/OperationOfficeForms/OvertimeWorkHourLimitForm';
import ImprinterNameForm from '@/components/OperationOfficeForms/ImprinterNameForm';
import CommentForm from '@/components/OperationOfficeForms/CommentForm';
import IttenkoForm from '@/components/OperationOfficeForms/IttenkoForm';
import TdsensorForm from '@/components/OperationOfficeForms/TdsensorForm';
import IttenkoTimesForm from '@/components/OperationOfficeForms/IttenkoTimesForm.vue';
import SensorAlertSettings from '@/components/OperationOfficeForms/SensorAlertSettings.vue';
import { INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL, SENSOR_ALERT_BATTERY_LEVEL } from '@/services/consts/operation_office';
import { useAuth } from '@/services/user/Auth0UserProvider';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
import { scrollToErrorComponent } from '@/services/validators';
import {
  OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST,
  OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST,
  OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON,
  OPERATION_OFFICE_DEFAULT_FULL_COLOR_SETTING,
  DEFAULT_ITTENKO_GET_HOURS,
  DEFAULT_ITTENKO_GET_MINUTES
} from "@/services/consts";
import i18n from '@/lang/i18n';

const props = defineProps({
  showFlash: { type: Function },
  switchContentLoading: { type: Function },
});
const loginWithRedirect = ref(null);
const router = useRouter();
const route = useRoute();
const title = ref('');
const isManagementOffice = ref(false);
const showableUpdateButton = ref(false);
const data = reactive({
  management_office: {},
  operation_office: {},
  errors: {},
});
const isUpdating = ref(false);
const updatable = ref(false);
const operationOfficeId = ref(null);
const windowInnerWidth = ref(window.innerWidth);

const initialManagementId = ref(null);
const initialManagementType = ref(null);

const ittenkoTimes = ref({
  ittenko_before_leaving: {
    hours: DEFAULT_ITTENKO_GET_HOURS,
    minutes: DEFAULT_ITTENKO_GET_MINUTES,
  },
  ittenko_after_returning: {
    hours: DEFAULT_ITTENKO_GET_HOURS,
    minutes: DEFAULT_ITTENKO_GET_MINUTES
  }
});

const initialParams = {
  external_urls: [{ name: '', url: '' }],
  tracking_service: 'docomap',
  accounting: {
    cutoff_date: '0',
    payment_site: '0',
    payment_day: '0',
  },
  weekly_aggregation: 'Sun',
  view_threshold: '0',
  tenko_type: "",
  enable_set_start_and_end_of_working_time: '0',
  view_1day_unit: '0',
  imprinter_name: ['', '', ''],
  sensor_notification_battery_level: SENSOR_ALERT_BATTERY_LEVEL[0],
};

const viewThreshold = [
  { label: i18n.global.t('pages.operationOffices.form.viewThreshold.display'), value: '1' },
  { label: i18n.global.t('pages.operationOffices.form.viewThreshold.hidden'), value: '0' },
];

const cooperationList = [
  { key: null, label: '' },
  { key: 'ittenko_cooperation', label: i18n.global.t('pages.operationOffices.form.cooperationList.ittenko') },
];

const dayList = [];

for (let i = 1; i < 29; i++) dayList.push({ key: i, label: i });

OPERATION_OFFICE_FORM_END_OF_MONTH_DATE_LIST.forEach((date) => {
  dayList.push(date);
})

const formatList = [
  { key: 'Y/m/d H:i:s', label: 'YYYY/MM/DD HH:mm:ss' },
  { key: 'Y-m-d H:i:s', label: 'YYYY-MM-DD HH:mm:ss' },
  { key: 'Ymd H:i:s', label: 'YYYYMMDD HH:mm:ss' },
];

(async () => {
  props.switchContentLoading(true);
  operationOfficeId.value = route.params.id;
  title.value = operationOfficeId.value
    ? i18n.global.t('pages.operationOffices.title.listEdit')
    : i18n.global.t('pages.operationOffices.title.listCreate');
  document.title = title.value;

  const selfAccount = await Api()
    .getSelfAccount()
    .catch((error) => {
      return errorHandle(error.data);
    });
  if (selfAccount.login_organization_type == 'App\\Models\\ManagementOffice')
    isManagementOffice.value = true;

  AuthorityCacheApi.getWithParse().then((authorityList) => {
    if (operationOfficeId.value) {
      showableUpdateButton.value = !!authorityList['octlink.operation_office.update'];
    } else {
      showableUpdateButton.value = !!authorityList['octlink.operation_office.create'];
    }
    if (!authorityList['octlink.operation_office.read']) {
      router.push('/');
    }
  });
  const { loginWithRedirect } = useAuth();
  loginWithRedirect.value = loginWithRedirect;

  if (operationOfficeId.value != '') {
    const response = await Api()
      .getOperationOffice(operationOfficeId.value)
      .catch((error) => {
        return errorHandle(error.data);
      });
    updatable.value = true;

    if (!response.weekly_aggregation) {
      alertDisplay(i18n.global.t('pages.operationOffices.form.message.isNotReckonedDate'))
    }
    if (response.ittenko_failed_at) {
      alertDisplay(i18n.global.t('pages.operationOffices.form.message.hasIttenkoFailedAt'))
    }
    data.operation_office = makeInitialParams(response);

    ittenkoTimes.value = {
      ittenko_before_leaving: {
        hours: response.ittenko_before_leaving_hours,
        minutes: response.ittenko_before_leaving_minutes,
      },
      ittenko_after_returning: {
        hours: response.ittenko_after_returning_hours,
        minutes: response.ittenko_after_returning_minutes,
      }
    }
  }
  props.switchContentLoading(false);
})();

onMounted(() => {
  if (operationOfficeId.value == '') {
    data.operation_office = initialParams;
    data.operation_office.settings_monthly_report = OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON;
    data.operation_office.settings_monthly_report.color_setting = OPERATION_OFFICE_DEFAULT_FULL_COLOR_SETTING;
    data.operation_office.sensor_alert_notification_mails = [INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL];
    updatable.value = true;
  }
});

function makeInitialParams(response) {
  response.settings_monthly_report =
    JSON.parse(response?.settings_monthly_report) ??
    OPERATION_OFFICE_DEFAULT_SETTINGS_MONTHLY_REPORT_JSON;
  response.imprinter_name = JSON.parse(response?.imprinter_name) ?? initialParams.imprinter_name;
  response.tracking_service =
    response.tracking_service ?? initialParams.tracking_service;
  response.view_threshold =
    response.view_threshold ?? initialParams.view_threshold;
  response.settings_monthly_report.view_1day_unit =
    response.settings_monthly_report.view_1day_unit ??
    initialParams.view_1day_unit;
  response.settings_monthly_report.enable_set_start_and_end_of_working_time =
    response.settings_monthly_report.enable_set_start_and_end_of_working_time ??
    initialParams.enable_set_start_and_end_of_working_time;
  if (response.external_urls?.length == 0 || response.external_urls == null) {
    response.external_urls = [{ name: '', url: '' }];
  }

  initialManagementId.value = response.management_officeable_id;
  initialManagementType.value = response.management_officeable_type;
  data.management_office = {
    id: response.management_officeable_id,
    type: response.management_officeable_type,
  };

  if (!response.sensor_alert_notification_mails.length) {
    response.sensor_alert_notification_mails[0] = INITIAL_SENSOR_ALERT_NOTIFICATION_MAIL
  }

  response.sensor_notification_battery_level = SENSOR_ALERT_BATTERY_LEVEL.find((battery) => {
    return battery.value == response.sensor_notification_battery_level
  })

  return response;
}

function alertDisplay(message) {
  props.showFlash(message, 'error', false);
}

function updateOperationOffice(officeName) {
  Object.keys(officeName).forEach((key) => {
    data.operation_office[key] = officeName[key];
  });
}
function updateTrackingService(trackingService) {
  data.operation_office.tracking_service = trackingService;
}
function updateSalesforceId(salesforceId) {
  data.operation_office.salesforce_id = salesforceId;
}
function updateContactName(contactName) {
  const key = Object.keys(contactName)[0];
  data.operation_office[key] = contactName[key];
}

function updateSettingsMontylyReportCooperation(_, obj) {
  const value = Object.values(obj)[0];
  data.operation_office['cooperation'] = value;
}

function updateSettingsMonthlyReportOverTime(type, list) {
  data.operation_office.settings_monthly_report['over_time_thresholds_type'] =
    type;
  data.operation_office.settings_monthly_report['over_time_thresholds'] = list;
}

function updateOperationOfficeAssociation(key, value) {
  if (Array.isArray(value)) {
    data.operation_office[key] = value;
  } else {
    const nestKey = Object.keys(value)[0];
    data.operation_office[key][nestKey] = value[nestKey];
  }
}

function submit() {
  isUpdating.value = true;
  const requestParams = makeRequestParams();
  const id = requestParams.id;
  if (id) {
    Api()
      .updateOperationOffice(id, requestParams)
      .then(() => {
        success();
      })
      .catch((error) => {
        errorHandle(error.response);
      });
  } else {
    Api()
      .createOperationOffice(requestParams)
      .then(() => {
        success();
      })
      .catch((error) => {
        errorHandle(error.response);
      });
  }
}

function success() {
  isUpdating.value = false;
  router.push('/operation-offices');
  props.showFlash(i18n.global.t('pages.common.messages.success'), 'success', true);
}

function makeRequestParams() {
  let zipcode = '';
  if (data.operation_office.zip1 || data.operation_office?.zip2) {
    zipcode = data.operation_office?.zip1 + '-' + data.operation_office?.zip2;
  }
  let splitZipcode = [];
  if (zipcode) splitZipcode = zipcode.split('-');

  const res = {
    id: data.operation_office?.id,
    management_officeable_id: data.management_office?.id,
    management_officeable_type: data.management_office?.type,
    external_urls: data.operation_office?.external_urls,
    work_status: data.operation_office?.work_status ?? '2',
    tracking_service: data.operation_office?.tracking_service,
    operation_office_name: data.operation_office?.operation_office_name,
    operation_office_name_kana:
      data.operation_office?.operation_office_name_kana,
    contact_name: data.operation_office?.contact_name,
    province: data.operation_office?.province,
    address1: data.operation_office?.address1,
    address2: data.operation_office?.address2,
    telephone_number: data.operation_office?.telephone_number ?? '',
    fax_number: data.operation_office?.fax_number ?? '',
    email: data.operation_office?.email,
    password_expire_days: data.operation_office?.password_expire_days ?? 0,
    is_active_password_expire_days: !!data.operation_office?.password_expire_days,
    login_error_limit_times: data.operation_office?.login_error_limit_times ?? 0,
    is_active_login_error_limit_times: !!data.operation_office?.login_error_limit_times,
    allocated_minute: data.operation_office?.allocated_minute,
    allocated_work: data.operation_office?.allocated_work,
    is_active_allocated: data.operation_office?.is_active_allocated,
    ip_keeps_effective: !!data.operation_office?.allowed_ips,
    ip_address_list: data.operation_office?.allowed_ips
      ? Object.values(data.operation_office?.allowed_ips)
      : [],
    zipcode: zipcode,
    zip1: splitZipcode[0] ?? null,
    zip2: splitZipcode[1] ?? null,
    comment: data.operation_office?.comment,
    imprinter_name: data.operation_office?.imprinter_name ?? initialParams.imprinter_name,
    settings_monthly_report: {
      color_setting_monthly:
        data.operation_office?.settings_monthly_report['color_setting_monthly'],
      color_setting:
        data.operation_office?.settings_monthly_report['color_setting'],
      bound_time_by_days:
        data.operation_office?.settings_monthly_report['bound_time_by_days'],
      bound_time_by_hours:
        data.operation_office?.settings_monthly_report['bound_time_by_hours'],
      over_time_thresholds_type:
        data.operation_office?.settings_monthly_report[
          'over_time_thresholds_type'
        ],
      over_time_thresholds:
        data.operation_office?.settings_monthly_report['over_time_thresholds'],
      over_time_working_hour_thresholds:
        data.operation_office?.settings_monthly_report[
          'over_time_working_hour_thresholds'
        ],
      csv_date_format:
        data.operation_office?.settings_monthly_report['csv_date_format'],
      report_deadline_day:
        data.operation_office?.settings_monthly_report['report_deadline_day'],
      view_1day_unit: data.operation_office?.view_1day_unit,
      enable_set_start_and_end_of_working_time:
        data.operation_office?.enable_set_start_and_end_of_working_time,
      cooperation: data.operation_office?.cooperation,
    },
    view_threshold: !!Number(data.operation_office?.view_threshold),
    weekly_aggregation:
      data.operation_office?.weekly_aggregation != 'null'
        ? data.operation_office.weekly_aggregation
        : null,
    is_active_ittenko: data.operation_office?.is_active_ittenko,
    tenko_type: data.operation_office?.tenko_type,
    ittenko_secret_key: data.operation_office?.ittenko_secret_key,
    ittenko_company_id: data.operation_office?.ittenko_company_id,
    tdsensor: data.operation_office?.tdsensor,
    ittenko_before_leaving_hours: ittenkoTimes.value.ittenko_before_leaving.hours,
    ittenko_before_leaving_minutes: ittenkoTimes.value.ittenko_before_leaving.minutes,
    ittenko_after_returning_hours: ittenkoTimes.value.ittenko_after_returning.hours,
    ittenko_after_returning_minutes: ittenkoTimes.value.ittenko_after_returning.minutes,
    sensor_notification_battery_level: data.operation_office.sensor_notification_battery_level.value,
    sensor_alert_notification_mail:
      data.operation_office?.tdsensor
        ? data.operation_office.sensor_alert_notification_mails[0].email
        : '',
  };

  // 管理会社の場合のみsalesforce_idを送信する
  if (isManagementOffice.value) {
    res['salesforce_id'] = data.operation_office?.salesforce_id;
  }

  return res;
}
function errorHandle(res) {
  switch (res.status) {
    case 401:
      loginWithRedirect({ appState: { targetUrl: location.pathname } });
      updatable.value = false;
      break;
    case 403:
      props.showFlash(i18n.global.t('pages.common.messages.notEditAuthority'), 'error', false);
      updatable.value = false;
      break;
    case 422:
      data.errors = res.data.errors;
      scrollToErrorComponent();
  }
  return (isUpdating.value = false);
}

function handleResize() {
  windowInnerWidth.value = window.innerWidth;
}

window.addEventListener('resize', handleResize);

onBeforeUnmount(() => {
  props.switchContentLoading(false);
});
</script>
<template>
  <div style="min-height: 45vw">
    <h4 class="content pl-3 w-100">{{ title }}</h4>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="mb-4">
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
            <h6 class="pl-4">
              {{ i18n.global.t('pages.operationOffices.form.managementOffice') }}
              <span style="color: red">✴︎</span></h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <ManagementOfficeAutoComplete
              v-model="data.management_office"
              :initialManagementId="initialManagementId"
              :initialManagementType="initialManagementType"
              selectFirstIfOnlyOne
              :error-messages="data?.errors['management_officeable_id']"
            />
          </v-col>
        </v-row>
        <ExternalUrlsForm
          :externalUrls="data.operation_office?.external_urls"
          @inputValue="updateOperationOffice"
          :errors="data?.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <TrackingServiceForm
          :isManagementOffice="isManagementOffice"
          :trackingService="data.operation_office?.tracking_service"
          @inputValue="updateTrackingService"
          :windowInnerWidth="windowInnerWidth"
        />
        <OperationOfficeNameForm
          :operationOfficeName="data.operation_office?.operation_office_name"
          :operationOfficeNameKana="
            data.operation_office?.operation_office_name_kana
          "
          :errors="data?.errors"
          @inputValue="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <template v-if="isManagementOffice">
          <SalesforceIdForm
            :salesforceId="data.operation_office?.salesforce_id"
            @inputValue="updateSalesforceId"
            :errors="data?.errors"
            :windowInnerWidth="windowInnerWidth"
          />
        </template>
        <ContactNameForm
          :contactName="data.operation_office?.contact_name"
          @inputValue="updateContactName"
          :errors="data.errors?.contact_name"
          :windowInnerWidth="windowInnerWidth"
        />
        <AddressForm
          :zipCode="data.operation_office?.zipcode"
          :province="data.operation_office?.province"
          :address1="data.operation_office?.address1"
          :address2="data.operation_office?.address2"
          :errors="data?.errors"
          @inputValue="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <TelephoneNumberForm
          :telephoneNumber="data.operation_office?.telephone_number"
          :faxNumber="data.operation_office?.fax_number"
          :errors="data?.errors"
          @inputValue="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <EmailForm
          :email="data.operation_office?.email"
          @inputValue="updateOperationOffice"
          :errors="data?.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <IttenkoForm
          :data="data.operation_office"
          @inputValue="updateOperationOffice"
          :errors="data.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <TdsensorForm
          :data="data.operation_office"
          @inputValue="updateOperationOffice"
          :errors="data.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <SensorAlertSettings
          v-if="data.operation_office.tdsensor"
          v-model="data.operation_office"
          :windowInnerWidth="windowInnerWidth"
          :errorMessages="data.errors"
        />
        <AllocatedWorkFrom
          :allocated_minute="data.operation_office?.allocated_minute"
          :allocated_work="data.operation_office?.allocated_work"
          @inputValue="updateOperationOffice"
          :errors="data?.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <AlertSettingForm
          :settingsMonthlyReport="
            data.operation_office?.settings_monthly_report
          "
          @inputOrChange="updateOperationOfficeAssociation"
          :errors="data.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <MaximumWorkingHoursForm
          :settingsMonthlyReport="
            data.operation_office?.settings_monthly_report
          "
          @selected="updateSettingsMonthlyReportOverTime"
          @inputTime="updateOperationOfficeAssociation"
          :errors="data.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <OvertimeWorkHourLimitForm
          :settingsMonthlyReport="
            data.operation_office?.settings_monthly_report
          "
          @inputTime="updateOperationOfficeAssociation"
          :errors="data.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <RadioForm
          :title="i18n.global.t('pages.operationOffices.form.reckonedDate')"
          :items="OPERATION_OFFICE_FORM_WEEKLY_AGGREGATION_LIST"
          emitKey="weekly_aggregation"
          :selectedItem="data.operation_office?.weekly_aggregation"
          cols="6"
          @selected="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
          :errors="data.errors['weekly_aggregation']"
        />
        <SelectForm
          :title="i18n.global.t('pages.operationOffices.form.reportDeadLineDay.title')"
          :items="dayList"
          emitKey="report_deadline_day"
          :selectedItem="
            data.operation_office?.settings_monthly_report?.report_deadline_day
          "
          @selected="updateOperationOfficeAssociation"
          :errors="data.errors?.['settings_monthly_report.report_deadline_day']"
          :windowInnerWidth="windowInnerWidth"
        />
        <SelectForm
          :title="i18n.global.t('pages.operationOffices.form.cooperationList.title')"
          :items="cooperationList"
          emitKey="cooperation"
          :selectedItem="
            data.operation_office?.settings_monthly_report?.cooperation
          "
          @selected="updateSettingsMontylyReportCooperation"
          :windowInnerWidth="windowInnerWidth"
        />
        <template v-if="data.operation_office.cooperation === 'ittenko_cooperation'">
          <IttenkoTimesForm
            v-model="ittenkoTimes"
            :errors="data?.errors"
            :windowInnerWidth="windowInnerWidth"
          />
        </template>
        <SelectForm
          :title="i18n.global.t('pages.operationOffices.form.csvDateFormat.title')"
          :items="formatList"
          emitKey="csv_date_format"
          :selectedItem="
            data.operation_office?.settings_monthly_report?.csv_date_format
          "
          @selected="updateOperationOfficeAssociation"
          :windowInnerWidth="windowInnerWidth"
        />
        <RadioForm
          :title="i18n.global.t('pages.operationOffices.form.viewThreshold.title')"
          :items="viewThreshold"
          emitKey="view_threshold"
          cols="4"
          :selectedItem="data.operation_office?.view_threshold"
          @selected="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <ImprinterNameForm
          :imprinterNames="data.operation_office?.imprinter_name"
          @inputImprinterName="updateOperationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <CommentForm
          :comment="data.operation_office?.comment"
          @inputComment="updateOperationOffice"
          :errors="data.errors?.comment"
          :windowInnerWidth="windowInnerWidth"
        />
        <div
          v-if="updatable && showableUpdateButton"
          class="mt-4 mb-4 mr-4"
          style="text-align: right"
        >
          <v-btn class="exec-btn" @click="submit" :loading="isUpdating">
            {{ i18n.global.t('pages.common.button.conservation') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
